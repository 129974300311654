<template>
    <div class="building">
        <div class="icon" style="">
            <div class="pic">
                <img style="width:453px;" src="/static/img/building.jpg"/>
            </div>
            <img style="width: 464px;z-index: 2;" src="/static/img/building.svg"/>
            <div class="text" >
                <div>功能建设中</div>
               
            </div>
            
            
        </div>
        
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>
.building{
    font-family: SourceHanSansCN-Medium;
    font-size: 30px;
    color: rgba(0,0,0,0.85);
    text-align: center;
    margin-bottom: 400px;
}
.icon{
    background-image: linear-gradient(180deg, #EDF6FF 0%, rgba(251,253,255,0.00) 100%);
    height: 357px;
    display: flex;
    justify-content: center;
    position: relative;
    img{
    }
    .text{
        font-family: SourceHanSansCN-Medium;
        font-size: 30px;
        color: #007CFF;
        position: absolute;
        top: 210px;
        z-index: 3;
    }
    .pic{
        position: absolute;
        top: 217px;
        z-index: 1;
    }

}

</style>


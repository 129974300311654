<template>
    <div class="user-info">
        <div class="">
            <div class="topic-search">
                <div class="demo-input-suffix">
                    <!--          其他-->
                    <el-form label-width="120px">
                        <el-row>

                            <el-col :span="6">
                                <el-form-item label="填报年度范围" style="padding: 5px;">
                                    <el-date-picker
                                        style="width: 40%;"
                                        v-model="searchForm.reportYearStart"
                                        type="year"
                                        placeholder="开始年份"
                                    />
                                    &nbsp
                                    至
                                    &nbsp
                                    <el-date-picker
                                        style="width: 40%;"
                                        v-model="searchForm.reportYearEnd"
                                        type="year"
                                        placeholder="结束年份"
                                    />
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="依托单位名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchForm.unitName" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="平台名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchForm.platformName" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>

                    </el-form>

                </div>


            </div>

        </div>
        <div style="padding-bottom: 10px;display: flex;justify-content: right;">
            <!--      <button type="button" class="btn btn-primary" @click="cleanParam" style="margin-right: 10px;">清空</button>-->
            <button type="button" class="btn btn-primary" @click="search">查询</button>
        </div>
        <el-table :data="tablePager.list" style="width: 100%" v-loading="loading">

            <!--      <el-table-column type="selection" width="55"/>-->
            <el-table-column type="index" label="序号" width="80"/>
            <el-table-column prop="unitName" label="依托单位名称"/>
            <el-table-column prop="platformName" label="平台/实验室"/>
            <el-table-column prop="platformTypeName" label="平台类型"/>
            <el-table-column prop="reportYear" label="填报年度"/>
            <el-table-column prop="projectYear" label="实验室立项年度"/>
            <el-table-column prop="examineStatusName" label="审核状态"/>
            <el-table-column prop="createTime" label="创建时间"/>

            <el-table-column label="操作" width="250">
                <template #default="scope">

                    <el-button style="margin-top: 10px;" type="success" size="small" @click="showDetail(scope.row.id)">详情</el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showEdit(scope.row.id)" v-if="(scope.row.examineStatus == -2 || scope.row.examineStatus == -1 || scope.row.examineStatus == 0) && !isExamine">编辑</el-button>


                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showFormMulti(scope.row.id)">填报详情</el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small"
                               @click="showExamine(scope.row.id)"
                               v-if="isExamine && scope.row.examineStatus == 1">
                        审核
                    </el-button>
                    <el-button style="margin-top: 10px;" type="warning" size="small" @click="showLogApprList(scope.row.id)">查看审核记录</el-button>

                </template>

            </el-table-column>
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>

    </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
import {useRoute} from 'vue-router'

export default {
    components: {SearchBar, ElTable, ElTableColumn, ElButton, ElIcon, ElDialog, ElResult, ElPopover, MoreFilled, Pager},
    setup() {
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
        const searchForm = ref({
            unitName: '',
            platformName: '',
        });
        const limit = ref(10);
        const tablePager = ref({
            total: 1,
            page: 1,
            list: [{}]
        })

        function loadData() {
            loading.value = true;
            searchForm.value.limit = limit.value
            searchForm.value.offset = (currentPage.value - 1) * limit.value
            proxy.$http.post('/gdsti2/lab/c/labReport/list.do', searchForm.value)
                .then(res => {
                    loading.value = false
                    if (res.data.state == 0) {
                        tablePager.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }

        function pageChange(index) {
            currentPage.value = index;
            loadData();
        }

        function search() {
            pageChange(1);
        }

        function showLiyou(id) {
            openDialog('showLiyou', '理由', '/hfapplication/gdsti/v/annualReport/showLiyouDialog.do?id=' + id, '', 1200, 650, function () {
                loadData()
            });
        }

        function showLogApprList(id) {
            openDialog("showLogApprList","审核记录","/gdsti2v/base/logAppr/list?sourceType=LAB_REPORT&sourceId="+ id,"",'95%', '95%',function(){

            });
        }

        function showDetail(id) {
            openDialog('showDetail', '详情', '/gdsti2v/lab/labReport/formMulti?disabled=true&reportId=' + id, '', '95%', '95%', function () {
                loadData()
            });
        }

        function showExamine(id) {
            openDialog('showExamine', '审核', '/gdsti2v/lab/labReport/formMulti?examineType=1&disabled=true&reportId=' + id, '', '95%', '95%', function () {
                loadData()
            });
        }

        function showEdit(id) {
            openDialog('showEdit', '编辑', '/gdsti2v/lab/labReport/form?id=' + id, '', '95%', '95%', function () {
                loadData()
            });
        }

        function showFormMulti(id) {
            let disabled = false;
            if(isExamine.value == true){
                disabled = true;
            }
            openDialog('showFormMulti', '填报', '/gdsti2v/lab/labReport/formMulti?reportId=' + id+'&disabled='+disabled, '', '95%', '95%', function () {
                loadData()
            });
        }

        const tablePagerSelect = ref({
            total: 1,
            page: 1,
            list: []
        })
        const isExamine = ref(false);
        const route = useRoute();
        onMounted(() => {
            loadData();
            isExamine.value = route.query.isExamine == 'true';
        })

        return {
            isExamine,
            tablePager,
            pageChange,
            search,
            currentPage,
            tablePagerSelect,
            showLiyou,
            showLogApprList,
            loading,
            searchForm,
            proxy,
            showDetail,
            showExamine,
            showEdit,
            showFormMulti
        }
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

</style>


<template>
    <div class="user-info">
        <el-descriptions
            class="margin-top"
            title="基本信息"
            :column="2"
            border
        >
            <template #extra>
<!--                <el-button type="primary">修改</el-button>-->
            </template>
            <el-descriptions-item>
                <template #label>
                    <div class="cell-item">账号</div>
                </template>
                {{userInfo.userCode}}

            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    <div class="cell-item">姓名</div>
                </template>
                {{userInfo.userName}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    <div class="cell-item">手机号码</div>
                </template>
                {{userInfo.mobilePhone}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    <div class="cell-item">用户角色</div>
                </template>
                {{userInfo.roleNameArr}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    <div class="cell-item">依托单位</div>
                </template>
                {{ unitInfo.name }}
            </el-descriptions-item>
        </el-descriptions>

    </div>
</template>

<script>
import { ElUpload, ElButton, ElIcon, ElDialog, ElResult} from 'element-plus'
import { Download, Plus } from '@element-plus/icons-vue'
import {ref, onMounted, computed, getCurrentInstance} from 'vue';

import CommonUtil from "@/utils/common"
import axios from "axios";


export default {
    components: {ElUpload, ElButton, ElIcon, ElDialog, ElResult, Plus},
    setup(){
        const {proxy} = getCurrentInstance();
        const userInfo = ref({});
        const unitInfo = ref({});
        const idcard1 = ref('');
        const idcard2 = ref('');
        const fileList1 = ref([]);
        const fileList2 = ref([]);
        const uploadUrl = computed(()=>{
            const url =  proxy.indexUrl + '/core/base/c/upload/upload';
            return url;
        });
		
		async function loadUserInfo() {
            let resultUserInfo = await CommonUtil.getUserInfo();
            if(resultUserInfo){
                userInfo.value = resultUserInfo;
            }

		}

        async function loadUserUnit() {
            let resultUnitInfo = await CommonUtil.getUnitInfo();
            if(resultUnitInfo){
                unitInfo.value = resultUnitInfo;
            }
        }

		
		onMounted(()=>{
            //加载用户基本信息
		    loadUserInfo();
            //加载用户依托单位信息
            loadUserUnit();
		})

        return {userInfo,idcard1, idcard2, fileList1, fileList2, uploadUrl,proxy,unitInfo}
    },
    methods:{
        toImage(id){
            return this.$getImage(id);
        },
        updateClick(){
            var data = {
              contractAddr: this.userInfo.contractAddr,
              userName: this.userInfo.userName,
              eMailAddr: this.userInfo.eMailAddr,
              mobilePhone: this.userInfo.mobilePhone,
              userCardFrontImg: this.idcard1,
              userCardBackImg: this.idcard2,
            }

          const url = '/hfapplication/gdsti/a/userCenter/updateUserInfo';
          this.proxy.$http.post(url,data).then(res=>{
            if(res.data.state==0){
              this.proxy.ElMessage.success("修改成功");
            }
          }).catch(err=>{

          });

        },
        handleIdcard1Success(response, uploadFile){
            let attachCode = response.result.list[0].attachInfo.attachCode;
            uploadFile['attachCode'] = attachCode
            this.idcard1 = attachCode
        },
        handleIdcard2Success(response, uploadFile){
            let attachCode = response.result.list[0].attachInfo.attachCode;
            uploadFile['attachCode'] = attachCode
            this.idcard2 = attachCode
        },
        beforeAvatarUpload(rawFile){
            if(rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png'){
                this.ElMessage.error('图片需要jpg或png格式');
                return false;
            }else if(rawFile.size/1024/1024 > 2){
                this.ElMessage.error('图片需要小于2MB');
                return false;
            }
            return true;
        }
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
.credit{
    margin-left: 15px;
    .credit-list{
        display: flex;
    }
    .idcard-uploader{
        margin-right: 31px;
        position: relative;
        width: 263px;
        height: 139px;
        
        .idcard-upload{
            width: 263px;
            height: 139px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index:1;
        }
        img{
            position: absolute;
            top: 0;
            left : 0;
            z-index:0;
			width: 263px;
			height: 139px;
        }
        .credit-text{
            z-index: 1;
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            color: #979797;
            margin-top: 20px;
        }
    }
    
}

</style>


<template>
    <div class="user-info">
        <!--        <data-resource-type></data-resource-type>-->
        <!--          <iframe src="/hfapplication/gdsti/v/dataCatalogueType/listJustShow3.do?isEscalation=isEscalation" style="width:100%; height : 1700px; scrolling : auto;" frameborder="0" target="_blank"></iframe>-->

        <div class="data-list">

            <div class="data-type-box">
                <div class="data-type-box-div">
                    <img :src="'/static/img/科研仪器2.svg'"/>
                    <div>
                        科研仪器
                        <p class="data-type-box-tip">请填报科研仪器数据</p>
                    </div>
                </div>
                <div class="data-type-box-buttons">

                    <el-button class="box-button" plain :icon="Download" @click="downInstrument">导入模板下载</el-button>
                    <el-button class="box-button" type="primary" :icon="Upload" @click="importInstrument">
                        Excel数据导入
                    </el-button>
                    <el-button class="box-button" type="primary" :icon="Edit" @click="goInstrument">数据填报</el-button>
                </div>
            </div>

            <div class="data-type-box">
                <div class="data-type-box-div">
                    <img :src="'/static/img/科学数据2.svg'"/>
                    <div>
                        科学数据
                        <p class="data-type-box-tip">请填报科学数据</p>
                    </div>
                </div>
                <div class="data-type-box-buttons">
                    <el-button class="box-button" plain :icon="Download" @click="downSci">导入模板下载</el-button>
                    <el-button class="box-button" type="primary" :icon="Upload" @click="importSci">
                        Excel数据导入
                    </el-button>
                    <el-button class="box-button" type="primary" :icon="Edit" @click="goSci">数据填报
                    </el-button>


                </div>
            </div>
            <div class="data-type-box">
                <div class="data-type-box-div">
                    <img :src="'/static/img/生物种质2.svg'"/>
                    <div>
                        生物种质与实验材料
                        <p class="data-type-box-tip">请填报生物种质与实验材料数据</p>
                    </div>
                </div>
                <div class="data-type-box-buttons">
                    <el-tooltip class="item" effect="dark" content="功能建设中" placement="top">
                        <el-button class="box-button" disabled plain :icon="Download">导入模板下载</el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="功能建设中" placement="top">
                        <el-button class="box-button" disabled type="info" :icon="Upload">Excel数据导入</el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="功能建设中" placement="top">
                        <el-button class="box-button" disabled type="info" :icon="Edit">数据填报</el-button>
                    </el-tooltip>

                </div>
            </div>
            <div class="data-type-box">
                <div class="data-type-box-div">
                    <img :src="'/static/img/科研成果2.svg'"/>
                    <div>
                        科研成果
                        <p class="data-type-box-tip">请填报科研成果数据</p>
                    </div>
                </div>
                <div class="data-type-box-buttons">
                    <el-tooltip class="item" effect="dark" content="功能建设中" placement="top">
                        <el-button class="box-button" disabled plain :icon="Download">导入模板下载</el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="功能建设中" placement="top">
                        <el-button class="box-button" disabled type="info" :icon="Upload">Excel数据导入</el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="功能建设中" placement="top">
                        <el-button class="box-button" disabled type="info" :icon="Edit">数据填报</el-button>
                    </el-tooltip>
                </div>
            </div>


        </div>


<!--        导入进度弹出框-->
        <el-dialog v-model="isUploadShow" title="导入完成前请勿刷新页面或关闭浏览器"
                   :show-close="isSuccessShow" style="text-align: center;" width="20%"
                   :close-on-press-escape="false" :close-on-click-modal="false">

            <el-progress type="circle" :percentage="percentageNumber" v-if="!isSuccessShow"></el-progress>
            <el-row v-if="isSuccessShow">
                <el-col :sm="6" :lg="24">
                    <el-result icon="success" title="" :subTitle="isSuccessStr">
                    </el-result>
                    <!--                    <div v-if="errorDataList.length > 0" style="text-align: center;padding-top: 10px;">-->
                    <!--                        <el-button type="primary" @click="exportToExcel()">下载存疑数据</el-button>-->
                    <!--                    </div>-->
                    <div style="text-align: center;padding-top: 10px;">
                        <el-button type="primary" @click="isUploadShow = !isUploadShow">确 定</el-button>
                    </div>
                </el-col>
            </el-row>


        </el-dialog>


    </div>


</template>

<script>
import DataResourceType from '../../DataResourceType'
import {Delete, Edit, Search, Share, Upload, Download} from '@element-plus/icons-vue'
import {ref, onMounted, getCurrentInstance} from 'vue';

export default {
    components: {DataResourceType, Search, Upload},
    setup() {
        const {proxy} = getCurrentInstance();

        // 导入相关参数
        const isUploadShow = ref(false);  //是否显示导入进度
        const isSuccessShow = ref(false);  //是否显示导入完成
        const isSuccessStr = ref(''); //导入完成显示文本
        const uploadProgressKey = ref('');  //导入进度key
        const percentageNumber = ref(false);    //导入进度百分比

        // 导入相关参数end
        function downInstrument() {
            window.open("/gdsti2/resource/c/resDataFormInstrument/downExportTemp.do");
        }

        function downSci() {
            window.open("/gdsti2/resource/c/resDataFormScience/downExportTemp.do");
        }

        function goInstrument() {
            let iframeUrl = "/gdsti2v/resource/resCatalogInfo/manage?catalogType=INSTRUMENT_DATA"
            proxy.$router.push({name: 'PlatformPages', path: 'platformPages', query: {iframeUrl: iframeUrl}})
        }

        function goSci() {
            let iframeUrl = "/gdsti2v/resource/resCatalogInfo/manage?catalogType=SCIENCE_DATA"
            proxy.$router.push({name: 'PlatformPages', path: 'platformPages', query: {iframeUrl: iframeUrl}})
        }

        function importInstrument() {
            //科研仪器excel导入
            const that = this;
            openDialog('selectPlatform', '选择归属平台（项目）', '/gdsti2v/lab/labPlatform/selectPlatform?isMultiple=false', '', 1200, 650, function (selectPlatformRes) {
                if (selectPlatformRes && selectPlatformRes.selectItemArr && selectPlatformRes.selectItemArr.length > 0) {
                    openDialog('fileUploaderDialog', '上传导入文件', '/gdsti2v/resource/resDataFormInstrument/importFileUploaderDialog?relateType=gdsti_res_data_form_instrument', {}, '50%', '50%', async function (fileUploaderDialogRes) {
                        console.log(fileUploaderDialogRes);
                        if (fileUploaderDialogRes && fileUploaderDialogRes.fileData && fileUploaderDialogRes.fileData.excelFile) {

                            //生成导入进度条key
                            const keyRes = await proxy.$http.post('/gdsti2/web/c/webManager/generateUploadProgressKey.do', {suffixStr: "exportIns"});
                            uploadProgressKey.value = keyRes.data.result;

                            const param = {
                                speedOfProgressKey: keyRes.data.result,
                                dataFileId: fileUploaderDialogRes.fileData.excelFile,
                                imageFileId: fileUploaderDialogRes.fileData.imageFile ? fileUploaderDialogRes.fileData.imageFile : '',//这里判断一下在传，不然传到后端会是undifind字符串
                                platformId: selectPlatformRes.selectItemArr[0].id
                            }
                            //开始导入前，重置进度条
                            resrtProgress();

                            //获取进度条定时器
                            let timer = setInterval(() => {
                                //需要定时执行的代码
                                // this.isSuccessShow = false;
                                proxy.$http.post('/gdsti2/web/c/webManager/getUploadProgress.do', {key: uploadProgressKey.value}).then(getUploadProgressRes => {
                                    if (getUploadProgressRes.data.state == 0) {
                                        percentageNumber.value = getUploadProgressRes.data.result;
                                    }
                                }).catch(err => {
                                    //如果异常，清空定时器
                                    clearInterval(timer);
                                })
                                //导入完成，清空定时器
                                if (percentageNumber.value == 100) {
                                    clearInterval(timer);
                                    //导入完成
                                }
                            }, 500);

                            //调用导入方法开始导入
                            //显示进度条
                            isUploadShow.value = true;
                            proxy.$http.post('/gdsti2/resource/c/resDataFormInstrument/importData.do', param).then(res => {
                                if (res.data.state == 0) {
                                    isSuccessShow.value = true;
                                    isSuccessStr.value = res.data.message;
                                }
                                clearInterval(timer);
                            }).catch(err => {
                                clearInterval(timer);
                            })

                        }

                    });
                }


            });
        }

        function importSci() {
//科研仪器excel导入
            const that = this;
            openDialog('selectPlatform', '选择归属平台（项目）', '/gdsti2v/lab/labPlatform/selectPlatform?isMultiple=false', '', 1200, 650, function (selectPlatformRes) {
                if (selectPlatformRes && selectPlatformRes.selectItemArr && selectPlatformRes.selectItemArr.length > 0) {
                    openDialog('fileUploaderDialog', '上传导入文件', '/gdsti2v/common/fileUploaderDialog?relateType=gdsti_res_data_form_science', {}, '50%', '50%', async function (fileUploaderDialogRes) {
                        if (fileUploaderDialogRes && fileUploaderDialogRes.fileList) {

                            //生成导入进度条key
                            const keyRes = await proxy.$http.post('/gdsti2/web/c/webManager/generateUploadProgressKey.do', {suffixStr: "exportSci"});
                            uploadProgressKey.value = keyRes.data.result;

                            const param = {
                                speedOfProgressKey: keyRes.data.result,
                                dataFileId: fileUploaderDialogRes.fileList[0].id,
                                platformId: selectPlatformRes.selectItemArr[0].id
                            }
                            //开始导入前，重置进度条
                            resrtProgress();

                            //获取进度条定时器
                            let timer = setInterval(() => {
                                //需要定时执行的代码
                                // this.isSuccessShow = false;
                                proxy.$http.post('/gdsti2/web/c/webManager/getUploadProgress.do', {key: uploadProgressKey.value}).then(getUploadProgressRes => {
                                    if (getUploadProgressRes.data.state == 0) {
                                        percentageNumber.value = getUploadProgressRes.data.result;
                                    }
                                }).catch(err => {
                                    //如果异常，清空定时器
                                    clearInterval(timer);
                                })
                                //导入完成，清空定时器
                                if (percentageNumber.value == 100) {
                                    clearInterval(timer);
                                    //导入完成
                                }
                            }, 500);

                            //调用导入方法开始导入
                            //显示进度条
                            isUploadShow.value = true;
                            proxy.$http.post('/gdsti2/resource/c/resDataFormScience/importData.do', param).then(res => {
                                if (res.data.state == 0) {
                                    isSuccessShow.value = true;
                                    isSuccessStr.value = res.data.message;
                                }
                                clearInterval(timer);
                            }).catch(err => {
                                clearInterval(timer);
                            })

                        }

                    });
                }


            });
        }


        //重置进度条，提示文本等等
        function resrtProgress(){
            isSuccessShow.value = false;
            percentageNumber.value = 0;
            isSuccessStr.value = '';
        }

        return {
            Search,
            Download,
            Upload,
            Edit,
            downInstrument,
            downSci,
            importInstrument,
            goInstrument,
            goSci,
            importSci,

            isUploadShow,
            isSuccessShow,
            isSuccessStr,
            uploadProgressKey,
            percentageNumber,
        }
    }
}
</script>

<style lang="less" scoped>
.data-list {
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.data-type-box {

    background-image: url('/static/img/矩形.png');
    background-repeat: no-repeat; /* 不重复 */
    background-size: 100% 100%; /* 宽度和高度都铺满父元素 */
    width: 45%;
    height: 200px;
    //cursor: pointer;
    margin: 18px 22px;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    box-sizing: border-box;
}

.data-type-box:hover {
    //filter: brightness(0.9);
    border: 1px solid #007EEC;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
}

.data-type-box-tip {
    color: #AAAAAA;
}

.data-type-box-div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Source Han Sans;
    font-size: 36px;
    font-weight: 500;
    line-height: normal;
    font-feature-settings: "kern" on;
    color: #3D3D3D;
    height: 75%;

    img {
        width: 77px;
        margin-right: 12px;

    }
}

.data-type-box-buttons {
    text-align: center;
    height: 25%;
}

.box-button {
    border-radius: 8px;
    opacity: 1;
}
</style>


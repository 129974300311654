<template>
    <div class="" style="position: relative;width: 100%;">
      <iframe :src="iframeUrl" width="100%" height="1600px" scrolling="auto" frameborder="0" ></iframe>
    </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElSwitch, ElIcon, ElPopover} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {useRoute} from 'vue-router'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {SearchBar, ElTable, ElTableColumn, ElButton, Pager, ElSwitch, ElIcon, ElPopover, MoreFilled},
    setup(){
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
        const searchKey = ref('');
        const route = useRoute();
        const tablePager = ref({
            total: 1,
            page: 1,
            list: [{id:'1', account:'YUN01',  platformName: '广东省显示材料与技术重点实验室', 
            deptName:'中山大学',
            platformType: '广东省重点实验室',
            parentDept:'广东省教育局',phone:'020-25421231', 
            email:'12345@126.com', address:'广州市天河区燕岭路231号', updateTime:'2022-01-21 12:13:12',
            status: 1, open: 0}]
        })
      const iframeUrl = ref('');

        function loadData(){
            loading.value = true;
            const url = '';
            proxy.$http.post(url, {limit: 10, offset: (currentPage.value-1)*10, searchKey: searchKey.value})
            .then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    tablePager.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        function pageChange(index){
            currentPage.value = index;
            loadData();
        }

        function search(val){
            searchKey.value = val;
            pageChange(1);
        }
        

        const multipleSelection = ref([])
        function handleSelectionChange(selections){
            multipleSelection.value = selections;
        }

        function handleUpdate(index){
            proxy.$router.push({name: 'PlatformPageEdit', path:'/platformPageEdit'})
        }
        function hanleDelete(index){

        }
        function addClick(){
            //TODO: 添加页面
        }


        function indexFormatter(row, column, cellValue, index){
            return row+1+'';
        }

        function handlePublish(index){
            console.log(index)
        }

        function statusFormatter(row, column, cellValue, index){
            console.log(cellValue)
            if(cellValue == 1){
                return '已发布';
            }else if(cellValue == 0){
                return '草稿';
            }
        }

        

        onMounted(()=>{
            // loadData();
          iframeUrl.value = route.query.iframeUrl;
        })

        return {tablePager,pageChange, search,currentPage, multipleSelection, indexFormatter, handleUpdate, hanleDelete,
        handlePublish, handleSelectionChange, statusFormatter,iframeUrl}
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
</style>


<template>
  <div class="user-info">
    <div class="base-info-list">
      <div class="base-info">
        <div class="info">名称：{{ industryZone.zoneName }}</div>
        <div class="info">园区类型：{{ industryZone.zoneType }}</div>
      </div>
      <div class="base-info">
        <div class="info">地址：{{ industryZone.zoneAddress }}</div>
        <div class="info">主导产业：{{ industryZone.mainIndustry }}</div>
      </div>
    </div>
    <div class="info">
      营业执照：
      <el-upload
          :action="uploadUrl"
          list-type="picture-card"
          :on-success="handleLicenseSuccess"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
          :limit="3"
      >
        <el-icon>
          <Plus/>
        </el-icon>
      </el-upload>
    </div>
    <div class="info">
      园区轮播：
      <el-upload
          :action="uploadUrl"
          list-type="picture-card"
          :on-success="handleImageSuccess"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
          :limit="10"
      >
        <el-icon>
          <Plus/>
        </el-icon>
      </el-upload>
    </div>
    <!--基本材料-->
  </div>
</template>

<script>
import {ElUpload, ElButton, ElIcon, ElDialog, ElResult} from 'element-plus'
import {Plus} from '@element-plus/icons-vue'
import {ref, onMounted, computed, getCurrentInstance} from 'vue';

export default {
  components: {ElUpload, ElButton, ElIcon, ElDialog, ElResult, Plus},
  setup() {
    const {proxy} = getCurrentInstance();
    const imageList = ref([]);
    const licenseFile = ref([])
    const industryZone = ref({});
    const uploadUrl = computed(() => {
      const url = proxy.indexUrl + '/hf/base/a/fileManage/fileUpload';
      return url;
    });

    function toImage(id) {
      return this.$getImage(id);
    };

    function handleLicenseSuccess(response, uploadFile) {
      let attachCode = response.result.list[0].attachInfo.attachCode;
      uploadFile['attachCode'] = attachCode
    }

    function handleImageSuccess(response, uploadFile) {
      let attachCode = response.result.list[0].attachInfo.attachCode;
      uploadFile['attachCode'] = attachCode
    }

    function beforeAvatarUpload(rawFile) {
      if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
        this.ElMessage.error('图片需要jpg或png格式');
        return false;
      } else if (rawFile.size / 1024 / 1024 > 2) {
        this.ElMessage.error('图片需要小于2MB');
        return false;
      }
      return true;
    }

    function loadInfo() {
      const url = '/hfapplication/gdsti/a/industryZone/getInfoByUserCode';
      proxy.$http.post(url).then(res => {
        if (res.data.state == 0) {
          industryZone.value = res.data.result;
        }
      }).catch(err => {

      });
    }


    onMounted(() => {
      loadInfo();
    })

    return {
      imageList, licenseFile, uploadUrl,industryZone,
      beforeAvatarUpload, handleLicenseSuccess, handleImageSuccess
    }
  }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

.base-info-list {
  padding-top: 48px;
  display: flex;

  .base-info:first-child {
    margin-right: 48px;
  }

}

.info {
  font-family: SourceHanSansCN-Medium;
  font-size: 20px;
  color: #3F3F3F;
  margin-bottom: 24px;
  display: flex;
}
</style>


<template>
    <div class="user-info">

        <div class="">
            <div class="topic-search">
                <div class="demo-input-suffix">
                    <!--          其他-->
                    <el-form label-width="120px">
                        <el-row>

                            <el-col :span="6">
                                <el-form-item label="依托单位名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchForm.unitName" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="平台名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchForm.platformName" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>

                    </el-form>

                </div>


            </div>

        </div>
        <div style="padding-bottom: 10px;display: flex;justify-content: right;">
            <!--      <button type="button" class="btn btn-primary" @click="cleanParam" style="margin-right: 10px;">清空</button>-->
            <button type="button" class="btn btn-primary" @click="search">查询</button>
        </div>
        <div class="collect-head">
            <div class="select">
                <el-button type="primary" class="table-btns" @click="showEdit('')">新增</el-button>
            </div>
        </div>

        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange"
                  v-loading="loading">
            <el-table-column type="index" label="序号" width="80"/>
            <el-table-column prop="platformName" label="平台名称"/>
            <el-table-column prop="platformTypeName" label="平台类型"/>
            <el-table-column prop="unitName" label="依托单位名称"/>
            <el-table-column prop="contactNumber" label="联系电话"/>
            <el-table-column prop="email" label="邮箱"/>
            <el-table-column prop="provinceName" label="所在省"/>
            <el-table-column prop="cityName" label="所在市"/>
            <el-table-column prop="examineStatusStr" label="审核状态"/>
            <el-table-column label="操作" width="250">
                <template #default="scope">
                    <el-button style="margin-top: 10px;" type="success" size="small" @click="showDetail(scope.row.id)">
                        详情
                    </el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showEdit(scope.row.id)"
                               v-if="scope.row.examineStatus == 0 || scope.row.examineStatus == 3">编辑
                    </el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small"
                               @click="showEditExamine(scope.row.id,1)"
                               v-if="scope.row.examineStatus == 0 || scope.row.examineStatus == 3">提交审核
                    </el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small"
                               @click="showEditExamine(scope.row.id,0)"
                               v-if="scope.row.examineStatus == 1 || scope.row.examineStatus == 2">撤回审核
                    </el-button>
                    <el-button style="margin-top: 10px;" type="warning" size="small"
                               @click="showLogApprList(scope.row.id)">查看审核记录
                    </el-button>

                    <el-button style="margin-top: 10px;" type="danger" size="small" @click="handleDelete(scope.row.id)">
                        删除
                    </el-button>


                </template>
            </el-table-column>
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>

    </div>
</template>

<script>
import {ElTable, ElTableColumn, ElButton, ElSwitch, ElIcon, ElPopover} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';

export default {
    components: {ElTable, ElTableColumn, ElButton, Pager, ElSwitch, ElIcon, ElPopover, MoreFilled},
    setup() {
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const limit = ref(10);

        const loading = ref(false);
        const searchForm = ref({
            unitName: '',
            platformName: '',
        });
        const tablePager = ref({
            total: 0,
            page: 1,
            list: []
        })
        const iframeUrl = ref('');

        function loadData() {
            loading.value = true;

            searchForm.value.limit = limit.value
            searchForm.value.offset = (currentPage.value - 1) * limit.value

            proxy.$http.post('/gdsti2/lab/c/labPlatform/list.do', searchForm.value)
                .then(res => {
                    loading.value = false
                    if (res.data.state == 0) {
                        tablePager.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }

        function pageChange(index) {
            currentPage.value = index;
            loadData();
        }

        function search() {
            pageChange(1);
        }


        const multipleSelection = ref([])

        function handleSelectionChange(selections) {
            multipleSelection.value = selections;
        }

        function handleDelete(id) {
            if (id == '') {
                return;
            }

            HFConfirm("确认要删除选中该信息吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {
                    loading.value = true;
                    proxy.$http.post('/gdsti2/lab/c/labPlatform/delete.do', {ids: id}).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            loadData();
                        }
                        loading.value = false;
                    }).catch(err => {
                        loading.value = false;
                    });
                }
            });


        }

        function indexFormatter(row, column, cellValue, index) {
            return row + 1 + '';
        }

        function handleView(index) {
            console.log(index)
        }


        onMounted(() => {
            loadData();
        })

        return {
            tablePager,
            pageChange,
            search,
            searchForm,
            currentPage,
            multipleSelection,
            indexFormatter,
            handleDelete,
            handleView,
            handleSelectionChange,
            iframeUrl,
            loading,
            loadData,
            proxy,
        }
    },
    methods: {

        showLogApprList(id) {

            openDialog("showLogApprList", "审核记录", "/gdsti2v/base/logAppr/list?sourceType=PLATFORM_INFO&sourceId=" + id, "", '95%', '95%', function () {

            });
        },
        showEditExamine(id, examineStatus) {
            var that = this;

            let title = "";
            if (examineStatus == 1) {
                title = "确认要提交审核吗？";
            }
            if (examineStatus == 0) {
                title = "确认要撤回审核吗？";
            }
            HFConfirm(title, "", "warning", function (isConfirm) {
                if (isConfirm) {
                    that.loading = true;
                    let param = {
                        id: id,
                        examineStatus: examineStatus,
                    }
                    that.proxy.$http.post('/gdsti2/lab/c/labPlatform/examine.do', param).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            that.loadData();
                        }
                        that.loading = false;
                    }).catch(err => {
                        that.loading = false;
                    });
                }
            });
        },
        showEdit(id) {
            let title = "新增"
            if (id) {
                title = "编辑"
            }
            var that = this;
            openDialog("showEdit", title, "/gdsti2v/lab/labPlatform/form?id=" + id, "", '95%', '95%', function () {
                that.loadData();
            });
        },
        showDetail(id) {
            var that = this;
            openDialog("showDetail", "详情", "/gdsti2v/lab/labPlatform/form?disabled=true&id=" + id, "", '95%', '95%', function () {
                that.loadData();
            });
        },
    }

}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
</style>


<template>
  <div class="user-info" v-loading="loading">
    <div class="collect-head">
      <SearchBar @onSearch="search"></SearchBar>
      <div class="select" >
        <span v-if="multipleSelection.length>0">已选：{{multipleSelection.length}} <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除选中</el-button></span>
        <!--                <el-button type="primary" class="table-btns" @click="showRequire()">新增</el-button>-->
      </div>
    </div>
    <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
      <!--            <el-table-column type="selection"  width="55"/>-->
      <!--            <el-table-column type="index" label="序号" width="80"/>-->
      <el-table-column prop="cname" label="仪器名称" />
      <el-table-column prop="applyUserName" label="申请用户名称" />
      <el-table-column prop="applyUserUnit" label="申请用户所在单位" />
      <el-table-column prop="useRequirements" label="使用需求" />
      <el-table-column prop="applyUserPhone" label="联系方式" />
      <el-table-column prop="createTime" label="提交时间" />
        <el-table-column prop="platformName" label="响应平台"/>
        <el-table-column prop="unitName" label="响应平台所属依托单位"/>
      <el-table-column prop="stateStr" label="确认状态" />
      <el-table-column  label="操作" width="220">
        <template #default="scope">
<!--          <a v-if="scope.row.state === 1" href="javascript:void(0)" class="primary" @click="updateApplyState(scope.row.id)">确认</a>-->
          <a style="" href="javascript:void(0)" class="danger" @click="pinjia(scope.row.id)">查看评价记录</a>
        </template>
      </el-table-column>
    </el-table>
    <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>

    <el-dialog
        title="评价"
        v-model="dialogVisible"
        width="50%"
    >
      <el-timeline v-loading="appraiseLogLoad">


        <el-timeline-item v-if="appraiseLogList && appraiseLogList.length > 0" v-for="item in appraiseLogList" :color="item.appraiseUserType === 1 ? '#0bbd87' : '#409EFF'" :timestamp="timestamp(item)" placement="top">
          <el-card>
            <p>{{ item.content }}</p>
          </el-card>
        </el-timeline-item>
          <el-empty description="暂无数据" v-else />
      </el-timeline>
        <div style="display: flex;justify-content: center;">
            <el-button @click="dialogVisible = false" style="margin-top: 50px;">关 闭</el-button>
        </div>




    </el-dialog>
  </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
  components: {SearchBar, ElTable, ElTableColumn, ElButton, ElIcon,ElDialog, ElResult, ElPopover, MoreFilled, Pager},
  setup(){
    const {proxy} = getCurrentInstance();
    const currentPage = ref(1);
    const loading = ref(false);
    const appraiseLogLoad = ref(false);
    const appraiseLogList = ref([

    ]);

    const searchKey = ref('');
    const tablePager = ref({
      total: 1,
      page: 1,
      list: []
    })
    const dialogVisible = ref(false);

    const appraiseLogParam  = ref({
      content: '',
      equipApplyId: '',
      // 申请方
      appraiseUserType: 1,
    });

    function loadData(){
      loading.value = true;
      // const url = '/hfapplication/gdsti/c/equipApply/applyList';
      const url = '/gdsti2/resource/c/resInstrumentUseApply/list';
      proxy.$http.post(url, {limit: 10, offset: (currentPage.value-1)*10, searchKey: searchKey.value,selectType:3})
          .then(res=>{
            loading.value = false
            if(res.data.state == 0){
              tablePager.value = res.data.result;
            }
          }).catch(err=>{
        loading.value = false
      })
    }

    function timestamp(item){
      let text = '';
      if(item.appraiseUserType === 1){
        text = '申请方 '+item.createTime;
      }else{
        text = '响应方 '+item.createTime;
      }
      return text;
    }

    //加载某条申请的评价记录
    function loadAppraiseLogData(instrumentApplyId){
      appraiseLogLoad.value = true;
      // const url = '/hfapplication/gdsti/c/appraiseLog/list';
      const url = '/gdsti2/resource/c/resInstrumentUseEvaluate/list';
      proxy.$http.post(url, {noPage: 1,instrumentApplyId: instrumentApplyId})
          .then(res=>{
            appraiseLogLoad.value = false
            if(res.data.state == 0){
              appraiseLogList.value = res.data.result;
            }
          }).catch(err=>{
        appraiseLogLoad.value = false
      })
    }

    function pageChange(index){
      currentPage.value = index;
      loadData();
    }

    function search(val){
      searchKey.value = val;
      pageChange(1);
    }

    const multipleSelection = ref([])
    function handleSelectionChange(selections){
      multipleSelection.value = selections;
    }

    //注销操作
    function updateLogOffState(status,dataTid){
      let msg = "";
      if(status === 1){
        msg = "确认要允许注销吗？注销后将无法启用！"
      }else if(status === 3){
        msg = "确认要不允许注销吗？不允许注销后平台负责人可再次发起注销申请。"
      }

      HFConfirm(msg, "", "warning", function(isConfirm){
        if(isConfirm){

          // const url = '/hfapplication/biform/c/form/updateLogOffState.do';
          const url = '/gdsti2/resource/c/resDataFormInstrument/updateLogOffState.do';
          proxy.$http.post(url, {dataTid: dataTid,logOffState:status}).then(res => {
            if (res.data.state == 0) {
              HFToastrTipResult(res.data);
              loadData();
            }
          }).catch(err => {

          });

        }
      });


    }
    //评价
    function pinjia(id){
      appraiseLogParam.value.equipApplyId = id;
      appraiseLogParam.value.content = '';
      loadAppraiseLogData(id);
      dialogVisible.value = true;
    }
    //确认申请
    function updateApplyState(id){
      HFConfirm("确认进行此项操作吗？", "", "warning", function(isConfirm){
        if(isConfirm){

          // const url = '/hfapplication/gdsti/c/equipApply/updateState.do';
          const url = '/gdsti2/resource/c/resInstrumentUseApply/edit';
          proxy.$http.post(url, {id: id,state:2}).then(res => {
            if (res.data.state == 0) {
              HFToastrTipResult(res.data);
              loadData();
            }
          }).catch(err => {

          });

        }
      });
    }

    //提交评价
    function sendAppraise(){
      // const url = '/hfapplication/gdsti/c/appraiseLog/edit.do';
      const url = '/gdsti2/resource/c/resInstrumentUseEvaluate/edit';
      proxy.$http.post(url, appraiseLogParam.value).then(res => {
        if (res.data.state == 0) {
          HFToastrTipResult(res.data);
          appraiseLogParam.value.content = '';
          loadAppraiseLogData(appraiseLogParam.value.equipApplyId);
        }
      }).catch(err => {

      });
    }

    function indexFormatter(row, column, cellValue, index){
      return row+1+'';
    }

    function handleDelete(index){
      console.log(index)
    }

    function handleUpdate(index){

    }
    function handlePublish(index){

    }

    function handleDeleteAll(){

    }



    const isShow = ref(false)
    function showRequire(){
      isShow.value = true;
    }

    function statusFormatter(row, column, cellValue, index){
      if(cellValue == 1){
        return '已处理';
      }else{
        return '待处理';
      }
    }



    onMounted(()=>{
      loadData();
    })

    return {tablePager,pageChange, search,currentPage, multipleSelection, indexFormatter, statusFormatter,
      handleDelete, handleDeleteAll, handleSelectionChange, handleUpdate, handlePublish,pinjia,dialogVisible,appraiseLogParam,
      isShow, showRequire,loading,updateLogOffState,updateApplyState,sendAppraise,appraiseLogLoad,appraiseLogList,timestamp
    }
  }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

</style>


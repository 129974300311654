<template>
    <ResDataProve></ResDataProve>
</template>

<script>
import ResDataProve from '../resource/ResDataProve';
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {ResDataProve},
    setup(){
        const {proxy} = getCurrentInstance();

        return {}
    }
}
</script>

<style lang="less" scoped>

</style>


<template>
    <div class="user-info">
        <div class="">
            <div class="topic-search">
                <div class="demo-input-suffix">
                    <!--          其他-->
                    <el-form label-width="120px">
                        <el-row>

                            <el-col :span="6">
                                <el-form-item label="依托单位名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchForm.unitName" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="平台名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchForm.platformName" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>

                    </el-form>

                </div>


            </div>

        </div>
        <div style="padding-bottom: 10px;display: flex;justify-content: right;">
            <!--      <button type="button" class="btn btn-primary" @click="cleanParam" style="margin-right: 10px;">清空</button>-->
            <button type="button" class="btn btn-primary" @click="search">查询</button>
        </div>
        <el-table :data="tablePager.list" style="width: 100%" v-loading="loading">
            <el-table-column type="index" label="序号" width="80"/>
            <el-table-column prop="platformName" label="平台名称"/>
            <el-table-column prop="platformTypeName" label="平台类型"/>
            <el-table-column prop="unitName" label="依托单位名称"/>
            <el-table-column prop="ecologicalChainName" label="所属创新生态链环节"/>
            <el-table-column prop="basicLaboratoryTypeName" label="所属类型"/>
            <el-table-column prop="examineStatusName" label="状态"/>
            <el-table-column label="操作" width="250">
                <template #default="scope">
                    <el-button
                        v-if="scope.row.examineStatus != -3"
                        style="margin-top: 10px;" type="success" size="small"
                               @click="showDetail(scope.row.lpId)">详情
                    </el-button>


                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showEdit(scope.row.lpId)"
                               v-if="(scope.row.examineStatus == -3 || scope.row.examineStatus == -2 || scope.row.examineStatus == -1 || scope.row.examineStatus == 0) && !isExamine">
                        编辑
                    </el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small"
                               @click="showEditExamine(scope.row.id,1)"
                               v-if="(scope.row.examineStatus == -2 || scope.row.examineStatus == -1 || scope.row.examineStatus == 0) && !isExamine">
                        提交审核
                    </el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small"
                               @click="showEditExamine(scope.row.id,0)"
                               v-if="(scope.row.examineStatus == 1 || scope.row.examineStatus == 2 || scope.row.examineStatus == 3) && !isExamine">
                        撤回审核
                    </el-button>

                    <el-button style="margin-top: 10px;" type="primary" size="small"
                               @click="showExamine(scope.row.lpId)"
                               v-if="isExamine && scope.row.examineStatus == 1">
                        审核
                    </el-button>

                    <el-button v-if="scope.row.examineStatus != -3"
                               style="margin-top: 10px;" type="warning" size="small"
                               @click="showLogApprList(scope.row.id)">查看审核记录
                    </el-button>

                </template>
            </el-table-column>
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>
        <el-dialog v-model="infoDialog" title="项目（机构）" width="80%">
            <div>
                <iframe :src="iframeUrl" style="width:100%; height : 800px; scrolling : auto;" frameborder="0"
                        target="_blank"></iframe>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {ElTable, ElTableColumn, ElButton, ElSwitch, ElIcon, ElPopover} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
import {useRoute} from 'vue-router'

export default {
    components: {ElTable, ElTableColumn, ElButton, Pager, ElSwitch, ElIcon, ElPopover, MoreFilled},
    setup() {
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
        const searchForm = ref({
            unitName: '',
            platformName: '',
        });
        const limit = ref(10);
        const tablePager = ref({
            total: 1,
            page: 1,
            list: []
        })
        const infoDialog = ref(false);
        const iframeUrl = ref('');

        function loadData() {
            loading.value = true;

            searchForm.value.limit = limit.value
            searchForm.value.offset = (currentPage.value - 1) * limit.value

            proxy.$http.post('/gdsti2/lab/c/labLabInfo/list.do', searchForm.value)
                .then(res => {
                    loading.value = false
                    if (res.data.state == 0) {
                        tablePager.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }

        function pageChange(index) {
            currentPage.value = index;
            loadData();
        }

        function search() {
            pageChange(1);
        }

        function updateClick(workplaceProjectId) {
            let iframeUrl = '/gdsti2/lab/v/laboratoryBasicInfo/edit.do?projectId=' + workplaceProjectId;
            proxy.$router.push({name: 'PlatformPages', path: 'platformPages', query: {iframeUrl: iframeUrl}})
        }
        const isExamine = ref(false);
        const route = useRoute();
        onMounted(() => {
            loadData();
            isExamine.value = route.query.isExamine == 'true';
        })

        return {
            tablePager,
            pageChange,
            search,
            currentPage,
            infoDialog,
            iframeUrl,
            loading,
            searchForm,
            loadData,
            updateClick,
            proxy,
            isExamine,
        }
    },
    methods: {
        showLogApprList(id) {

            openDialog("showLogApprList", "审核记录", "/gdsti2v/base/logAppr/list?sourceType=LAB_INFO&sourceId=" + id, "", '95%', '95%', function () {

            });
        },
        showEditExamine(id, examineStatus) {
            var that = this;

            let title = "";
            if (examineStatus == 1) {
                title = "确认要提交审核吗？";
            }
            if (examineStatus == 0) {
                title = "确认要撤回审核吗？";
            }
            HFConfirm(title, "", "warning", function (isConfirm) {
                if (isConfirm) {
                    that.loading = true;
                    let param = {
                        id: id,
                        examineStatus: examineStatus,
                    }
                    that.proxy.$http.post('/gdsti2/lab/c/labLabInfo/examine.do', param).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            that.loadData();
                        }
                        that.loading = false;
                    }).catch(err => {
                        that.loading = false;
                    });
                }
            });
        },
        showEdit(platformId) {
            var that = this;
            openDialog("showEdit", "编辑", "/gdsti2v/lab/labLabInfo/form?platformId=" + platformId, "", '95%', '95%', function () {
                that.loadData();
            });
        },
        showExamine(platformId) {
            var that = this;
            openDialog("showExamine", "审核", "/gdsti2v/lab/labLabInfo/form?isExamine=true&examineType=unitAdmin&platformId=" + platformId, "", '95%', '95%', function () {
                that.loadData();
            });
        },
        showDetail(platformId) {
            var that = this;
            openDialog("showDetail", "详情", "/gdsti2v/lab/labLabInfo/form?disabled=true&platformId=" + platformId, "", '95%', '95%', function () {
                that.loadData();
            });
        },
    }

}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
</style>


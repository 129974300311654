<template>
    <div class="user-info">


        <div style="display: flex;justify-content: center;">
            <el-card style="width: 100%;margin-top: 30px;">
                <template #header>Key 与 Secret</template>
                <div class="common-layout">
                    <el-container>
                        <el-aside width="200px">
                            <img src="/static/img/user/icons8-key.svg" style="width: 100%"/></el-aside>
                        <el-main>
                            <el-form class="demo-form-inline" label-width="50px">
                                <el-row>
                                    <el-col :span="10">
                                        <el-form-item label="Key">
                                            <el-input :readonly="true" :disabled="isBlank(key)" v-model="key"
                                                      placeholder="点击生成按钮进行生成" style="width: 100%;"/>
                                            <el-tag style="padding: 10px;">Key将作为您调用api接口的唯一凭证，生成后不可修改或删除</el-tag>

                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3">
                                        <el-form-item>
                                            <el-button type="primary" :disabled="!isBlank(key)" @click="generateKey">生成Key</el-button>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3">
                                        <el-form-item>
                                            <el-button type="primary" @click="copy(this.key)" :disabled="isBlank(key)">复制</el-button>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row style="margin-top: 50px;">
                                    <el-col :span="10">
                                        <el-form-item label="Secret">
                                            <el-input :readonly="true" :disabled="isBlank(secret)" v-model="secret"
                                                      placeholder="点击生成按钮进行生成（不过你得先生成Key）" style="width: 100%;"/>
                                            <el-tag style="padding: 10px;">Secret需要单独生成，且当您认为Secret存在泄露风险或其他原因，您可以随时重新生成Secret</el-tag>

                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3">
                                        <el-form-item>
                                            <el-button type="primary" :disabled="isBlank(key)" @click="generateSecret">生成Secret</el-button>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3">
                                        <el-form-item>
                                            <el-button type="primary" @click="copy(this.secret)" :disabled="isBlank(secret)">复制</el-button>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>

                        </el-main>
                    </el-container>
                </div>

            </el-card>
        </div>

    </div>
</template>

<script>

import CommonUtil from "@/utils/common"
import { ElMessage } from 'element-plus';
import {ref, onMounted, getCurrentInstance, reactive} from 'vue';
import useClipboard from 'vue-clipboard3'

export default {
    components: {},
    setup() {

        const {proxy} = getCurrentInstance();
        const { toClipboard } = useClipboard();
        const loading = ref(false);

        const key = ref('')
        const secret = ref('')

        const keyDisabled = ref(true)
        const secretDisabled = ref(false)

        function isBlank(obj){
            return CommonUtil.isBlank(obj);
        }

        function loadData() {
            loading.value = true;
            const param = {
                userCode: proxy.$store.state.userInfo.userCode,
                noPage: 1,
            }
            proxy.$http.post('/gdsti2/resource/c/resApiKey/list.do', param).then(res => {
                if (res.data.state == 0) {
                    if(res.data.result && res.data.result.length > 0){
                        key.value = res.data.result[0].apiKey
                        secret.value = res.data.result[0].apiSecret
                    }

                }
                loading.value = false;
            }).catch(err => {
                loading.value = false;
            });
        }

        function generateKey() {

            HFConfirm("确认生成Key吗？这将作为您通过api进行数据汇交的唯一凭证，生成后不可修改。", "", "warning", function (isConfirm) {
                if (isConfirm) {
                    loading.value = false;
                    proxy.$http.post('/gdsti2/resource/c/resApiKey/generateKey', {}).then(res => {
                        if (res.data.state == 0) {

                            ElMessage.success("操作成功！");
                            loadData()
                        }
                        loading.value = false;
                    }).catch(err => {
                        loading.value = false;
                        ElMessage.error("操作失败，请联系网站管理员处理。");
                    });
                }
            });
        }

        function generateSecret() {
            HFConfirm("确认生成Secret吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {
                    loading.value = false;
                    proxy.$http.post('/gdsti2/resource/c/resApiKey/generateSecret', {}).then(res => {
                        if (res.data.state == 0) {

                            ElMessage.success("操作成功！");
                            loadData()
                        }
                        loading.value = false;
                    }).catch(err => {
                        loading.value = false;
                        ElMessage.error("操作失败，请联系网站管理员处理。");
                    });
                }
            });
        }
        
        

        async function copy(text) {
            if(!text){
                ElMessage.error('复制失败，需要复制的文本内容为空')
            }else{
                try {
                    console.log(text);
                    await toClipboard(text)
                    ElMessage.success('复制成功')
                } catch (e) {
                    console.log(e)
                    ElMessage.warning('您的浏览器不支持复制：', e)
                }
            }

        }

        onMounted(() => {
            loadData();
        })

        return {
            key,
            secret,
            keyDisabled,
            secretDisabled,
            generateKey,
            generateSecret,
            copy,
            isBlank,
        }
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

</style>


<template>
    <div class="user-info">
        <div class="collect-head">
            <SearchBar @onSearch="search"></SearchBar>
            <div class="select" >
                <span v-if="multipleSelection.length>0">已选：{{multipleSelection.length}} <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除选中</el-button></span>
                <el-button type="primary" class="table-btns" @click="showRequire()">新增</el-button>
            </div>
        </div>
        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection"  width="55"/>
            <el-table-column type="index" label="序号" width="80"/>
            <el-table-column prop="user" label="主任名称" width="80" />
            <el-table-column prop="title" label="实验室标题" />
            <el-table-column prop="type" label="学科领域" />
            <el-table-column prop="area" label="所在地区" />
            <el-table-column prop="dept" label="依托单位" />
            <el-table-column prop="year" label="实验室立项年度" />
            <el-table-column prop="deptName" label="主管部门" />
            <el-table-column prop="reportYear" label="填报年限" />
            <el-table-column prop="date" label="更新时间" />
            <el-table-column prop="result" label="审核状态" />
            <el-table-column  label="操作" width="80">
                <template #default="scope">
                    <el-popover placement="bottom" :width="50" trigger="click">
                        <template #reference>
                            <el-icon class="link-more"><MoreFilled /></el-icon>
                        </template>
                        <div class="option-list">
                            <a href="javascript:void(0)" class="primary" @click="handleUpdate(scope.$index)">审批处理</a>
                            <a href="javascript:void(0)" class="danger" @click="handleDelete(scope.$index)">删除</a>
                        </div>
                    </el-popover>
                    

                    
                </template>
            </el-table-column>
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>

        <el-dialog v-model="isShow" title="请填写您的需求">
            需求内容
        </el-dialog>
    </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {SearchBar, ElTable, ElTableColumn, ElButton, ElIcon,ElDialog, ElResult, ElPopover, MoreFilled, Pager},
    setup(){
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
        const searchKey = ref('');
        const tablePager = ref({
            total: 1,
            page: 1,
            list: [
                {
                  // id:'1',
                  // user: '张三',
                  // title:'广东省核电安全企业重点实验室',
                  // type: '资源环境',
                  // area: '广东省深圳市福田区',
                  // dept: '中广核研究院有限公司',
                  // year: '2018',
                  // deptName: '深圳市科技创新委员会',
                  // reportYear: '2020',
                  // name:"广东省中医证候临床研究重点实验室",
                  // result: '待提交',
                  // date: '2021-12-12'
                }
            ]
        })

        function loadData(){
            loading.value = true;
            const url = '';
            proxy.$http.post(url, {limit: 10, offset: (currentPage.value-1)*10, searchKey: searchKey.value})
            .then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    tablePager.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        function pageChange(index){
            currentPage.value = index;
            loadData();
        }

        function search(val){
          searchKey.value = val;
            pageChange(1);
        }

        const multipleSelection = ref([])
        function handleSelectionChange(selections){
            multipleSelection.value = selections;
        }


        function indexFormatter(row, column, cellValue, index){
            return row+1+'';
        }

        function handleDelete(index){
            console.log(index)
        }

        function handleUpdate(index){

        }
        function handlePublish(index){
            
        }

        function handleDeleteAll(){

        }

        

        const isShow = ref(false)
        function showRequire(){
            isShow.value = true;
        }

        function statusFormatter(row, column, cellValue, index){
            if(cellValue == 1){
                return '已处理';
            }else{
                return '待处理';
            }
        }

        

        onMounted(()=>{
            // loadData();
        })

        return {tablePager,pageChange, search,currentPage, multipleSelection, indexFormatter, statusFormatter,
        handleDelete, handleDeleteAll, handleSelectionChange, handleUpdate, handlePublish,
        isShow, showRequire}
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

</style>


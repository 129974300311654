<template>
    <div class="user-info">
        <div class="collect-head">
            <SearchBar @onSearch="search"></SearchBar>
            <div class="select" v-if="multipleSelection.length>0">
                已选：{{multipleSelection.length}} <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除选中</el-button>
            </div>
        </div>
        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection"  width="55"/>
            <el-table-column type="index" label="序号" width="80"/>
            <el-table-column prop="title" label="标题" />
            <el-table-column prop="filename" label="文件名称" />
            <el-table-column prop="type" label="下载类型" width="120" />
            <el-table-column prop="date" label="下载时间" width="180" />
            <el-table-column  label="操作" width="80">
                <template #default="scope">
                    <a href="javascript:void(0)" @click="handleDelete(scope.$index)">删除</a>
                </template>
            </el-table-column>
        </el-table>
        <Pager :totalPath="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>
    </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton} from 'element-plus';
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {SearchBar, ElTable, ElTableColumn, ElButton, Pager},
    setup(){
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
        const searchKey = ref('');
        const tablePager = ref({
            total: 1,
            page: 1,
            list: [{id:'1', title:'新能源与乡村振兴的共赢发展', filename: '新能源与乡村振兴的共赢发展报名文档.word', type:'赛题资源', date:'2021-12-12 14:23:12', status:1}]
        })

        function loadData(){
            loading.value = true;
            const url = '';
            proxy.$http.post(url, {limit: 10, offset: (currentPage.value-1)*10, searchKey: searchKey.value})
            .then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    tablePager.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        function pageChange(index){
            currentPage.value = index;
            loadData();
        }

        function search(val){
            searchKey.value = val;
            pageChange(1);
        }

        const multipleSelection = ref([])
        function handleSelectionChange(selections){
            multipleSelection.value = selections;
        }


        function indexFormatter(row, column, cellValue, index){
            return row+1+'';
        }

        function handleDelete(index){
            console.log(index)
        }

        function handleDeleteAll(){

        }

        

        onMounted(()=>{
            // loadData();
        })

        return {tablePager,pageChange, search,currentPage, multipleSelection, indexFormatter, 
        handleDelete, handleDeleteAll, handleSelectionChange}
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
</style>


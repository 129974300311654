<template>
  <div class="user-info" v-loading="loading">
    <div class="collect-head">
      <!--            <SearchBar @onSearch="search"></SearchBar>-->
      <div class="topic-search">
        实验室名称 <input type="text" class="search-input" placeholder="请输入" v-model="searchKey">
        <button type="button" class="btn btn-primary" @click="search">查询</button>
      </div>
      <div class="select" >
        <span v-if="multipleSelection.length>0">已选：{{multipleSelection.length}} <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除选中</el-button></span>
        <!--                <el-button type="primary" class="table-btns" @click="showRequire()">新增</el-button>-->
      </div>
    </div>
    <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
      <!--            <el-table-column type="selection"  width="55"/>-->
      <!--            <el-table-column type="index" label="序号" width="80"/>-->
      <el-table-column prop="year" label="填报年度" />
      <el-table-column prop="projectName" label="实验室名称" />
      <el-table-column prop="theValue" label="学科领域" />
      <el-table-column prop="contractAddr" label="所在地区" />
      <el-table-column prop="hrwName" label="依托单位" />
      <el-table-column prop="statusStr" label="审核状态" />
      <el-table-column  label="操作" width="220">
        <template #default="scope">

          <a v-if="scope.row.examineStatus === -1" href="javascript:void(0)" class="primary" @click="show(scope.row.id)">查看</a>
          <a v-if="scope.row.examineStatus === 0" href="javascript:void(0)" class="primary" @click="shenhe(scope.row.id)">审核</a>
        </template>
      </el-table-column>
    </el-table>
    <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>

    <el-dialog v-model="isShow" title="请填写您的需求">
      需求内容
    </el-dialog>
  </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
  components: {SearchBar, ElTable, ElTableColumn, ElButton, ElIcon,ElDialog, ElResult, ElPopover, MoreFilled, Pager},
  setup(){
    const {proxy} = getCurrentInstance();
    const currentPage = ref(1);
    const loading = ref(false);
    const searchKey = ref('');
    const tablePager = ref({
      total: 1,
      page: 1,
      list: [{id:'1', name:"广东省中医证候临床研究重点实验室",
        deptName: "广东省中医院",
        type: '医学科学',
        year: '1999',
        result: '待提交', date: '2021-12-12'}]
    })

    function loadData(){
      loading.value = true;
      const url = '/hfapplication/gdsti/c/annualReport/examineList';

      proxy.$http.post(url, {limit: 10, offset: (currentPage.value-1)*10, projectName: searchKey.value,checkStatus: 1})
          .then(res=>{
            loading.value = false
            if(res.data.state == 0){
              tablePager.value = res.data.result;
            }
          }).catch(err=>{
        loading.value = false
      })
    }

    function pageChange(index){
      currentPage.value = index;
      loadData();
    }

    function search(){
      pageChange(1);
    }

    const multipleSelection = ref([])
    function handleSelectionChange(selections){
      multipleSelection.value = selections;
    }

    //注销操作
    function updateLogOffState(status,dataTid){
      let msg = "";
      if(status === 1){
        msg = "确认要允许注销吗？注销后将无法启用！"
      }else if(status === 3){
        msg = "确认要不允许注销吗？不允许注销后平台负责人可再次发起注销申请。"
      }

      HFConfirm(msg, "", "warning", function(isConfirm){
        if(isConfirm){

          const url = '/hfapplication/biform/c/form/updateLogOffState.do';
          proxy.$http.post(url, {dataTid: dataTid,logOffState:status}).then(res => {
            if (res.data.state == 0) {
              HFToastrTipResult(res.data);
              loadData();
            }
          }).catch(err => {

          });

        }
      });


    }

    function indexFormatter(row, column, cellValue, index){
      return row+1+'';
    }

    function handleDelete(index){
      console.log(index)
    }

    function handleUpdate(index){

    }
    function handlePublish(index){

    }

    function updateState(projectId) {
      let iframeUrl = '/hfapplication/gdsti/v/laboratoryBasicInfo/edit.do?ischeck=1&projectId='+projectId;
      proxy.$router.push({name: 'PlatformPages', path:'platformPages', query: {iframeUrl : iframeUrl}})
    }

    function show(id) {
      let iframeUrl = '/hfapplication/gdsti/v/annualReport/edit.do?isExamine=2&id='+id;
      proxy.$router.push({name: 'PlatformPages', path:'platformPages', query: {iframeUrl : iframeUrl}})
    }

    function shenhe(id) {
      let iframeUrl = '/hfapplication/gdsti/v/annualReport/edit.do?isExamine=1&id='+id;
      proxy.$router.push({name: 'PlatformPages', path:'platformPages', query: {iframeUrl : iframeUrl}})
    }

    function handleDeleteAll(){

    }



    const isShow = ref(false)
    function showRequire(){
      isShow.value = true;
    }

    function statusFormatter(row, column, cellValue, index){
      if(cellValue == 1){
        return '已处理';
      }else{
        return '待处理';
      }
    }



    onMounted(()=>{
      loadData();
    })

    return {tablePager,pageChange, search,currentPage, multipleSelection, indexFormatter, statusFormatter,
      handleDelete, handleDeleteAll, handleSelectionChange, handleUpdate, handlePublish,
      isShow, showRequire,loading,updateLogOffState,updateState,shenhe,show,searchKey}
  }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
.topic-search{

  line-height: 25px;
  font-size: 14px;


  .search-input{
    background: #FFFFFF;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 2px;
    padding: 5px 9px;
    margin-left: 9px;
    margin-right: 16px;
    font-size: 14px;
    line-height: 14px;
  }
  .btn{
    display: inline-block;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}
</style>


<template>
    <div>
        <div class="collect-head">
            <SearchBar @onSearch="search"></SearchBar>
            <div class="select" >
                <span v-if="multipleSelection.length>0">已选：{{multipleSelection.length}} <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除选中</el-button></span>
            </div>
        </div>
        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection"  width="55"/>
            <el-table-column type="index" label="序号" width="80"/>
            <el-table-column prop="deptName" label="实验室名称" />
            <el-table-column prop="dept" label="依托单位名称" />
            <el-table-column prop="type" label="学科领域" />
            <el-table-column prop="year" label="立项年度" />
            <el-table-column prop="name" label="主任名称" />
            <el-table-column prop="name2" label="副主任名称" />
            <el-table-column prop="mainName" label="委员会主任" />
            <el-table-column prop="date" label="修改时间" />
            <el-table-column prop="result" label="审核状态" />
            <el-table-column prop="updateResult" label="修改后审核状态" />
    
            <el-table-column  label="操作" width="80">
                <template #default="scope">
                    <el-popover placement="bottom" :width="50" trigger="click">
                        <template #reference>
                            <el-icon class="link-more"><MoreFilled /></el-icon>
                        </template>
                        <div class="option-list">
                            <a href="javascript:void(0)" class="primary" @click="handleUpdate(scope.$index)">编辑提交</a>
                            <a href="javascript:void(0)" class="danger" @click="handleDelete(scope.$index)">删除</a>
                            
                        </div>
                    </el-popover>
                    

                    
                </template>
            </el-table-column>
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>
        

    </div>
</template>

<script>
import SearchBar from './common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon, ElSwitch} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from './common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    components: {SearchBar, ElTable, ElTableColumn, ElButton, ElIcon,ElDialog, ElResult, ElPopover, MoreFilled, Pager, ElSwitch},
    setup(){
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
        const searchKey = ref('');
        const tablePager = ref({
            total: 1,
            page: 1,
            list: [
            //     {id:'1',  name:'张三',  dept:'实验室所属单位',
            // deptName: '广东省消防科学技术重点实验室',
            // type: '医学科学',
            // year:'1999',
            // name2: '李四、王五',
            // mainName: '孙少香',
            // result: '待提交',
            // updateResult: '待审核',
            // date: '2021-12-12'}
            ]
        })


        function loadData(){
            loading.value = true;
            const url = '';
            proxy.$http.post(url, {limit: 10, offset: (currentPage.value-1)*10, searchKey: searchKey.value})
            .then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    tablePager.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        function pageChange(index){
            currentPage.value = index;
            loadData();
        }

        function search(val){
            searchKey.value = val;
            pageChange(1);
        }

        const multipleSelection = ref([])
        function handleSelectionChange(selections){
            multipleSelection.value = selections;
        }


        function indexFormatter(row, column, cellValue, index){
            return row+1+'';
        }

        function handleDelete(index){
            console.log(index)
        }

        function handleUpdate(index){

        }
        function handleView(index){
            
        }

        function handleDeleteAll(){

        }

        

        const isShow = ref(false)
        function showRequire(){
            isShow.value = true;
        }

        function statusFormatter(row, column, cellValue, index){
            if(cellValue == 1){
                return '已处理';
            }else{
                return '待处理';
            }
        }

        function backClick(){
            proxy.$router.back();
        }

        onMounted(()=>{
            // loadData();
        })

        return {tablePager,pageChange, search,currentPage, multipleSelection, indexFormatter, statusFormatter,
        handleDelete, handleDeleteAll, handleSelectionChange, handleUpdate, handleView,
        isShow, showRequire,
        backClick}
    }
}
</script>

<style lang="less" scoped>
@import '../../public/static/css/userBaseInfo.less';
</style>


<template>
    <div class="user-body">
        <div class="user-content">
            <div class="user-menu">
                <div class="menu-box" v-for="(item, index) in menuList">
                    <div class="menu-head"><img :src="$getImage(item.imgCode)" width="32" height="32" v-if="item.imgCode && item.imgCode != ''" />{{item.navName}}</div>
                    <div class="menu-list">
                        <router-link :to="gotoUrl(child)" class="menu-item" :class="{active: currentPath==child.navCode}" v-for="(child, childIndex) in item.children">{{child.navName}}
                        </router-link>

                    </div>
                </div>
            </div>
            <div class="right">
                <div class="right-content">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, getCurrentInstance, watch, onMounted} from 'vue'
import {useRoute} from 'vue-router'

import CommonUtil from "@/utils/common"

export default {
    setup() {
        const {proxy} = getCurrentInstance();
        const active = ref(0);

        const currentPath = ref('');
        const currentPathName = ref('');
        const route = useRoute();


        const menuList = ref([]);   //用户中心菜单
        


        function changeActive(index, e, route) {
            active.value = index;
        }

        let path = route.path;
        currentPath.value = path.substring(path.lastIndexOf('/') + 1);

        watch(route, (newVal, oldVal) => {
            let path = newVal.path;
            currentPath.value = path.substring(path.lastIndexOf('/') + 1);
        });

        function changeCurrentPathName(pathName){
            currentPathName.value = pathName
        }

        function gotoUrl(item){
            let urlArr = item.linkUrl.split('?');
            let queryJSON = {};
            let paramsArr = [];
            if(urlArr[1] && urlArr[1] != ''){
              paramsArr = urlArr[1].split('&');
              
              paramsArr.forEach(param => {
                  const [key, value] = param.split('=');
                  queryJSON[key] = decodeURIComponent(value);
              });
            }

            queryJSON['navCode'] = item.navCode;    //导航栏编码

            //跳转
            return { path: urlArr[0], query: queryJSON };
        }

        function loadMenu(){    //加载菜单
            proxy.$http.post('/core/cms/c/webNavigate/treelistauth?isOpen=ALL', {}).then(res => {
                if(res.data.state == 0){
                    let parentCode = null
                    for (let item of res.data.result) {
                        if (item.navCode == 'USER_CENTER') {
                            //暂时只添加一级结点，等后面支持二级后再处理
                            parentCode = item.treeCode
                            console.log('用户中心', item);
                        }
                    }

                    for (let item of res.data.result) {
                        if (parentCode && parentCode == item.treeCode.substring(0, 3)) {
                            //父类相同

                            if (item.nodeDeep == 2) {
                                //2级菜单
                                item.children = [];
                                menuList.value.push(item)
                            } else if (item.nodeDeep == 3) {
                                //3级菜单

                                for (let menu of menuList.value) {
                                    if (menu.treeCode == item.parentTreeCode) {
                                        menu.children.push(item)
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    // console.log('menuList.value', menuList.value);
                }
                
            }).catch(err => {

            })
        }


        onMounted(() => {
            // console.log('用户角色：', proxy.$store.state.userInfo);

            loadMenu();
        })

        return {active, changeActive, currentPath,changeCurrentPathName,currentPathName, menuList, gotoUrl}
    }
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 1440px) {
    .user-content {
        width: 97%;

    }
}

@media screen and (max-width: 1439px) {
    .user-content {
        width: 97%;
    }
}

.user-body {
    background: #f6f6f6;

}

.user-content {
    padding-top: 24px;
    padding-bottom: 100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.right {
    flex: 1;

    .right-content {
        background: #fff;
    }

    width: calc(100% - 230px);
}

.user-menu {
    margin-right: 30px;
    width: 230px;

    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .menu-box {
        .menu-head {
            font-family: SourceHanSansCN-Medium;
            font-size: 18px;
            color: #A5A5A5;
            line-height: 18px;
            background: #EAEAEA;
            display: flex;
            align-items: center;
            padding: 11px 42px;

            img {
                margin-right: 6px;
            }
        }

        .menu-list {
            background: #fff;

            .menu-item {
                display: block;
                font-family: SourceHanSansCN-Medium;
                font-size: 18px;
                color: rgba(0, 0, 0, 0.85);
                line-height: 52px;
                padding-left: 63px;
                cursor: pointer;
            }

            .menu-item:hover {
                color: #007EEC;
            }

            .menu-item.active {
                color: #007EEC;
                border-left: 5px solid #007EEC;
                padding-left: 58px;
            }
        }
    }
}
</style>


<template>
    <div class="user-info" v-loading="loading">
        <div class="info-title">专项汇交证明</div>
        <Form class="form" ref="target" v-slot="{ errors}">
            <div class="form-group">
                <label class="control-label col-1">申请标题<span class="required">*</span></label>
                <div class="col-8">
                    <Field type="text" name="title" :rules="checkRequired" class="form-control" v-model="form.title"
                           placeholder="请输入项目名称"/>
                    <div class="error">{{ errors.title }}</div>
                </div>
            </div>
            <!--      <div class="form-group">-->
            <!--        <label class="control-label col-1">单位名称<span class="required">*</span></label>-->
            <!--        <div class="col-8">-->
            <!--          <Field type="text" name="deptName" :rules="checkRequired" class="form-control" v-model="form.deptName"-->
            <!--                 placeholder="请输入单位名称"/>-->
            <!--          <div class="error">{{ errors.deptName }}</div>-->
            <!--        </div>-->
            <!--      </div>-->
            <div class="form-group">
                <label class="control-label col-1">申请理由<span class="required">*</span></label>
                <div class="col-8">
                    <Field :rules="checkRequired" name="reason" v-model="form.reason">
                        <textarea rows="6" v-model="form.reason" class="form-control" placeholder="请输入内容"></textarea>
                    </Field>
                    <div class="error">{{ errors.reason }}</div>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-1">备注</label>
                <div class="col-10 hint-block">
                    <div>
                        <Field name="remarks" v-model="form.remarks">
                            <textarea rows="4" v-model="form.remarks" class="form-control"
                                      placeholder="请输入内容"></textarea>
                        </Field>
                        <div class="error">{{ errors.remarks }}</div>
                    </div>
                    <div class="hint-text">
                        若有其它汇交情况可在此处标明，如：元数据记录数、实体集数据量、接收标本数量、接收标准物质数量、接收论文数量、考察/调查报告数量、志书典籍/专著/图集数量、软件工具数量等。
                    </div>

                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-1">汇交清单</label>
                <div class="col-11">
                    <el-table :data="tablePager.list" style="width: 100%;margin-left: 10px;">
                        <!--            <el-table-column type="selection" width="55"/>-->
                        <el-table-column type="index" label="序号" width="80"/>
                        <el-table-column prop="typeName" label="数据/资源类型"/>
                        <el-table-column prop="catalogName" label="所属目录"/>
                        <el-table-column prop="cname" label="中文名称"/>
                        <el-table-column prop="ename" label="英文名称"/>
                        <el-table-column prop="subject" label="学科分类" :formatter="subjectFormatter">
                            <template #default="scope">
                              {{subjectFormatter(scope.row.subject)}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="innerId" label="仪器所在单位内部编码"/>
                        <el-table-column label="操作" width="80">
                            <template #default="scope">
                                <a href="javascript:void(0)" class="danger" @click="handleDelete(scope.$index)">删除</a>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="bottom-btns">
                <el-button type="default" @click="back">返回</el-button>
                <el-button type="primary" v-loading="loading" @click="handleCommit">申请提交</el-button>
            </div>
        </Form>
        <el-dialog v-model="showDialog" @closed="backToResDataProve">
            <div class="dialog-result">
                <p>尊敬的TEST01:</p>
                <p>您好！</p>
                <p>我们已收到您的专项汇交证明申请，我们会尽快审核，审核成功后，会返回到您【用户中心-消息管理】，请留意消息。</p>

                <p class="right-text">广东省科技基础条件平台中心</p>
                <p class="right-text">2022年6月10日</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {Form, Field} from 'vee-validate';
import {ElButton, ElTable, ElTableColumn, ElDialog, ElResult} from 'element-plus';
import {ref, reactive, onMounted, getCurrentInstance} from 'vue';
import {useRoute} from 'vue-router'

export default {
    components: {Form, Field, ElButton, ElTable, ElTableColumn, ElDialog, ElResult},
    setup() {
        const {proxy} = getCurrentInstance();
        const route = useRoute();
        const query = route.query;
        const form = reactive({
            title: '',
            reason: '',
            remarks: '',
            ids: '',
            catalogTypes: '',
        })
        const target = ref(null)
        const loading = ref(false);

        const showDialog = ref(false)

        const tablePager = ref({
            total: 1,
            page: 1,
            list: []
        })

        const subjectList = ref([])

        const checkRequired = value => {
            if (!value) {
                return '请输入内容';
            } else {
                return true;
            }
        }


        const handleCommit = () => {
            console.log('handleCommit');

            target.value.validate().then(valid => {
                if (valid) {
                    loading.value = true;
                    //把选择的数据id组装起来
                    var ids = [];
                    var catalogTypeArr = [];
                    for (const obj of tablePager.value.list) {
                        ids.push(obj.id);
                        catalogTypeArr.push(obj.catalogType);
                    }
                    form.dataIds = ids.join(",");
                    form.catalogTypes = catalogTypeArr.join(",");
                    proxy.$http.post('/gdsti2/resource/c/resRemittanceProve/edit', form).then(res => {
                        loading.value = false;
                        proxy.$router.go(-2);
                    }).catch(err => {
                        loading.value = false
                    });
                    return true;
                } else {
                    return false;
                }
            }).catch(err => {
                return false;
            })
        }

        function loadData() {

        }

        function handleDelete(index) {
            tablePager.value.list.splice(index, 1);
        }


        function subjectFormatter(cellValue) {
            // console.log('subjectList', cellValue, subjectList.value);
            if(cellValue){
                let textArr = [];
                let subjectCodeArr = cellValue.split(',');
                for(let item of subjectCodeArr){
                    for(let dictItem of subjectList.value){
                        if(item == dictItem.dictVal){
                            textArr.push(dictItem.dictName);
                        }
                    }
                }
                return textArr.join(',');
            }
            return cellValue;
        }
        async function init(){
            subjectList.value = await loadDataDictList('SUBJECT');

            loadData();
        }

        async function loadDataDictList(dictTypeCode) {    //加载数据字典
            let dataDictList = [];
            await proxy.$http.post('/core/base/c/dictInfo/list?noPage=1&typeCode=' + dictTypeCode, {}).then(res => {

                dataDictList = res.data.result;

            }).catch(err => {

            })

            return dataDictList;
        }

        onMounted(() => {
            init();

            console.log(query);
            let remittanceDataList = JSON.parse(query.remittanceDataList);
            tablePager.value.list = remittanceDataList;
        })

        return {target, form, loading, handleCommit, checkRequired, tablePager, handleDelete, showDialog, subjectFormatter}
    },
    methods: {
        back() {
            this.$router.back();
        },
        backToResDataProve() {
            this.$router.replace({name: 'ResDataProve', path: 'resDataProve'})
        }
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

.next-btn {
    margin: 0 auto;
}

.form {
    width: 100%;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 26px;

    .form-control {
        width: 350px;
    }
}

.dialog-result {
    font-family: SourceHanSansCN-Medium;
    font-size: 20px;
    color: #3F3F3F;
    line-height: 26px;
}

.hint-block {
    display: flex;

    .hint-text {
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 18px;
    }
}
</style>


<template>
    <div class="user-info">
        <div class="form-inline search-form">
            <div class="form-group">
                <label class="control-label">消息状态</label>
                <select class="form-control">
                    <option value="">全部</option>
                    <option value="0">未读</option>
                    <option value="1">已读</option>
                </select>
            </div>
            
            <button type="button" class="btn btn-primary">查询</button>
        </div>
        <el-table :data="tablePager.list" style="width: 100%" :show-header="false">
            <el-table-column  label="状态" width="180">
                <template #default="scope">
                    <el-tag class="tag" effect="dark" v-if="scope.row.status==0">未读</el-tag>
                    <el-tag class="tag" effect="plain" v-else>已读</el-tag>
                </template>
            </el-table-column>
            <el-table-column  label="消息内容">
                <template #default="scope">
                    <div class="msg-title">{{ scope.row.title }}</div>
                    <div class="msg-content">{{ scope.row.content }} <router-link class="primary" :to="{name:'MessageDetail', path: 'messageDetail', query:{id: scope.row.id}}">详情>></router-link></div>
                </template>
            </el-table-column>
            <el-table-column  label="时间" width="180">
                <template #default="scope">
                    <div class="msg-title">&nbsp;</div>
                    <div class="msg-content">{{scope.row.date}}</div>
                </template>
            </el-table-column>
            
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>
    </div>
</template>

<script>
import {ElTable, ElTableColumn, ElTag} from 'element-plus';
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {ElTable, ElTableColumn, Pager, ElTag},
    setup(){
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
        const tablePager = ref({
            total: 1,
            page: 1,
            list: [{id:'1', title:'消息名称2', content:'这是消息内容2', date: '2021-12-23', status:1},{id:'1', title:'消息名称1', content:'这是消息内容1', date: '2021-12-23', status:0}]
        })

        function loadData(){
            loading.value = true;
            const url = '';
            proxy.$http.post(url, {limit: 10, offset: (currentPage.value-1)*10})
            .then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    tablePager.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        function pageChange(index){
            currentPage.value = index;
            loadData();
        }

        function statusFormatter(row, column, cellValue, index){
            if(cellValue == 1){
                return '已读';
            }else{
                return '未读';
            }
        }

        function indexFormatter(row, column, cellValue, index){
            return row+1+'';
        }

        onMounted(()=>{
            // loadData();
        })

        return {tablePager,pageChange, currentPage, statusFormatter, indexFormatter}
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
.tag{
    padding-left:8px;
    padding-right: 8px;
}
.msg-title{
    font-family: SourceHanSansCN-Medium;
    font-size: 14px;
    line-height: 14px;
    color: rgba(0,0,0,0.85);
}
.msg-content{
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    line-height: 14px;
    color: rgba(0,0,0,0.50);
    margin-top:8px;
}
</style>


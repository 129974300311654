<template>
    <div :id="id" type="text/plain" class="editor"></div>
</template>


<script>
import "../../public/static/ueditor/ueditor.config.js"
import "../../public/static/ueditor/ueditor.all.min.js"
import "../../public/static/ueditor/lang/zh-cn/zh-cn.js"
import "../../public/static/ueditor/ueditor.parse.min.js"
import {ref, onMounted, getCurrentInstance} from 'vue'
export default {
    name: 'ueditor',
    props: {
        id:{
            type: String,
            default:'editor'
        },
        config:{
            type: Object,
            default: {}
        }
    },
    data(){
        return {
            editor: null,
        }
    },
    setup(){
        const {proxy} = getCurrentInstance();

    

        return {}
    },
    mounted(){
       
        this.$nextTick(()=>{
            this.editor = UE.getEditor(this.id, this.config)
            this.editor.addListener('ready', ()=>{
                this.editor.focus();
            })
        })
        
    },
    methods:{
        getContent: function (){
          console.log("调用此方法getContent")
            return this.editor.getContent();
        },
        setContent: function (data){
          console.log("调用此方法",data)
          return this.editor.setContent(data);
        },
        clearContent: function(){
            this.editor.execCommand('cleardoc');
        }
    },
    beforeUnmount(){
        if(this.editor !== null && this.editor.destroy){
            this.editor.destroy();
        }
    }
    
}
</script>

<style lang="less" scoped>


</style>


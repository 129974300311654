<template>
    <div class="user-info" v-loading="loading">
        <div class="collect-head">
            <SearchBar @onSearch="search"></SearchBar>
            <div class="select" v-if="multipleSelection.length>0">
                已选：{{multipleSelection.length}} <el-button type="danger" class="table-btns" @click="handleCancelAll()">取消收藏</el-button>
            </div>
        </div>
        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection"  width="55"/>
            <el-table-column type="index" label="序号" width="120"/>
            <el-table-column label="资源标题">
                <template #default="scope">
                    <el-link type="primary" :href="'/instrument?id=' + scope.row.dataId + '&catalogType=' + (scope.row.collectType === 1 ? 'SCIENCE_DATA' : 'INSTRUMENT_DATA')" target="_blank">{{scope.row.dataName}}</el-link>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="收藏时间" />
            <el-table-column  label="操作" width="180">
                <template #default="scope">
                    <el-button style="margin-top: 10px;" type="danger" size="small" @click="handleCancel(scope.row.id)">取消收藏</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>
    </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton} from 'element-plus';
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {SearchBar, ElTable, ElTableColumn, ElButton, Pager},
    setup(){
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
        const searchValue = ref('');
        const tablePager = ref({
            total: 1,
            page: 1,
            list: [
                // {id:'1', title:'高能同步xxx', platform: '所属平台', status:1}
            ]
        })

        function loadData(){
            var userInfo = proxy.$store.state.userInfo;
            let userCode = userInfo.userCode;
            loading.value = true;
            const url = '/gdsti2//web/c/webUserCollect/list';
            proxy.$http.post(url, {limit: 10, offset: (currentPage.value-1)*10, searchValue: searchValue.value,userCode: userCode})
                .then(res=>{
                    loading.value = false
                    if(res.data.state == 0){
                        tablePager.value = res.data.result;
                    }
                }).catch(err=>{
                loading.value = false
            })
        }

        function pageChange(index){
            currentPage.value = index;
            loadData();
        }

        function search(keyStr){
            searchValue.value = keyStr;
            pageChange(1);
        }

        const multipleSelection = ref([])
        function handleSelectionChange(selections){
            multipleSelection.value = selections;
        }


        function indexFormatter(row, column, cellValue, index){
            return row+1+'';
        }

        function handleCancel(id){
            HFConfirm("确认要取消收藏吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {
                    loading.value = true;
                    proxy.$http.post('/gdsti2/web/c/webUserCollect/delete.do', {ids: id}).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            loadData();
                        }
                        loading.value = false;
                    }).catch(err => {
                        loading.value = false;
                    });
                }
            });

        }

        function handleCancelAll(){
            var ids = [];
            for (const obj of multipleSelection.value) {
                ids.push(obj.id);
            }
            HFConfirm("确认要取消收藏选中项吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {
                    loading.value = true;
                    proxy.$http.post('/gdsti2/web/c/webUserCollect/delete.do', {ids: ids.join(",")}).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            loadData();
                        }
                        loading.value = false;
                    }).catch(err => {
                        loading.value = false;
                    });
                }
            });
        }



        onMounted(()=>{
            loadData();
        })

        return {tablePager,pageChange, search,currentPage, multipleSelection, indexFormatter, loading,
            handleCancel, handleCancelAll, handleSelectionChange}
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
</style>


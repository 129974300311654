<template>
    <ResRecordProve></ResRecordProve>
</template>

<script>
import ResRecordProve from './ResRecordProve'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {ResRecordProve},
    setup(){
        const {proxy} = getCurrentInstance();

        return {}
    }
}
</script>

<style lang="less" scoped>

</style>


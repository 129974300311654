<template>
    <Notice :title="title" :content="content" @nextClick="nextClick"></Notice>
</template>

<script>
import Notice from '../../../components/Notice'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {Notice},
    setup(){
        const {proxy} = getCurrentInstance();
        const loading = ref(false)

        const title = "数据/资源收录证明";
        const content = "<h3>数据/资源收录证明申请须知</h3><p>第一章　总　　则</p>";
        function getNotice(){

        }

        function nextClick(){
            proxy.$router.push({name: 'ResRecordProveSelection', path: 'resRecordProveSelection'})
        }

        onMounted(()=>{
            getNotice()
        })

        return {title, content, nextClick}
    }
}
</script>

<template>
  <div class="user-info">
    <div class="collect-head">
      <SearchBar @onSearch="search"></SearchBar>
      <div class="select">
        <el-button type="primary" class="table-btns" @click="getProjectList()">申请变更</el-button>
      </div>
    </div>
    <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange" v-loading="loading">
      <el-table-column type="index" label="序号" width="80"/>
      <el-table-column prop="projectName" label="平台名称"/>
      <el-table-column prop="laboratoryTypeName" label="平台类型"/>
      <el-table-column prop="hrwName" label="依托单位名称"/>
      <el-table-column prop="contactNumber" label="联系电话"/>
      <el-table-column prop="email" label="邮箱" :formatter="defaultFormatter"/>
      <el-table-column prop="address" label="地址" :formatter="defaultFormatter"/>
      <el-table-column prop="examineStatus" label="审核状态" :formatter="statusFormatter"/>
      <!--            <el-table-column  label="账号状态" >-->
      <!--                <template #default="scope">-->
      <!--                    <el-switch-->
      <!--                        v-model="scope.row.open"-->
      <!--                        size="large"-->
      <!--                        inline-prompt-->
      <!--                        active-text="启用"-->
      <!--                        inactive-text="停用"-->
      <!--                    />-->
      <!--                </template>-->
      <!--            </el-table-column>-->
      <el-table-column label="操作" width="80">
        <template #default="scope">
          <el-popover placement="bottom" :width="120" trigger="click">
            <template #reference>
              <el-icon class="link-more">
                <MoreFilled/>
              </el-icon>
            </template>
            <div class="option-list">
              <a href="javascript:void(0)" class="primary" v-if="scope.row.examineStatus == -2 || scope.row.examineStatus == -1" @click="examine(scope.row.id)">提交审核</a>
              <a href="javascript:void(0)" class="primary" @click="handleUpdate(scope.row.id)">查看</a>
              <a href="javascript:void(0)" class="danger" @click="hanleDelete(scope.row.id)">删除</a>
            </div>
          </el-popover>


        </template>
      </el-table-column>
    </el-table>
    <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>
    <el-dialog v-model="infoDialog" width="80%" :title="infoDialogTitle" >
      <div>
        <iframe :src="iframeUrl" style="width:100%; height : 800px; scrolling : auto;" frameborder="0"></iframe>
      </div>
    </el-dialog>

    <el-dialog v-model="isShow" title="选择平台（实验室）">
      <div class="collect-head">
        <div class="select">
          <el-button type="primary" class="table-btns" @click="projectSelect()">选择</el-button>
        </div>
      </div>
      <el-table :data="tablePagerSelect.list" style="width: 100%" @selection-change="handleSelectionChangeProject">
        <el-table-column width="60px">
          <template v-slot="scope">
            <!-- label值要与el-table数据id实现绑定 -->
            <el-radio v-model="projectId" :label="scope.row.id" @change="handleRowChangeSelect(scope.row)">{{""}}
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="80"/>
        <el-table-column prop="projectName" label="平台名称"/>
        <el-table-column prop="establishYear" label="成立年度"/>
        <el-table-column prop="address" label="地址"/>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElSwitch, ElIcon, ElPopover} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';

export default {
  components: {SearchBar, ElTable, ElTableColumn, ElButton, Pager, ElSwitch, ElIcon, ElPopover, MoreFilled},
  setup() {
    const {proxy} = getCurrentInstance();
    const currentPage = ref(1);
    const loading = ref(false);
    const searchKey = ref('');
    const tablePager = ref({
      total: 1,
      page: 1,
      list: [{
        id: '1', account: 'YUN01', platformName: '广东省显示材料与技术重点实验室',
        deptName: '中山大学',
        platformType: '广东省重点实验室',
        parentDept: '广东省教育局', phone: '020-25421231',
        email: '12345@126.com', address: '广州市天河区燕岭路231号', updateTime: '2022-01-21 12:13:12',
        status: 1, open: 0
      }]
    })

    const infoDialog = ref(false);
    const iframeUrl = ref('');
    const infoDialogTitle = ref('');
    const isShow = ref(false)

    function loadData() {
      isLogin();
      loading.value = true;
      const url = '/hfapplication/gdsti/c/relyonWorkplaceProjectUpdateLog/list.do';
      proxy.$http.post(url, {limit: 10, offset: (currentPage.value - 1) * 10, projectName: searchKey.value})
          .then(res => {
            loading.value = false
            if (res.data.state == 0) {
              tablePager.value = res.data.result;
            }
          }).catch(err => {
        loading.value = false
      })
    }
    function isLogin() {
      const url = '/hfapplication/gdsti/a/userCenter/isLogin';
      proxy.$http.post(url).then(res=>{
        if(res.data.state==0){
        }
      }).catch(err=>{

      });
    }
    function pageChange(index) {
      currentPage.value = index;
      loadData();
    }

    function search(searchKeyStr) {
      searchKey.value = searchKeyStr;
      pageChange(1);
    }


    const multipleSelection = ref([])

    function handleSelectionChange(selections) {
      multipleSelection.value = selections;
    }

    function handleUpdate(id) {
      if (id == '') {
        return;
      }
      // this.infoDialog = true;
      // this.iframeUrl = "/hfapplication/gdsti/v/relyonWorkplaceProjectUpdateLog/dialogEdit.do?justShow=yes&id="+ id;

      openDialog("updateLogShowInfo","查看","/hfapplication/gdsti/v/relyonWorkplaceProjectUpdateLog/dialogEdit.do?justShow=yes&id="+ id,"",900, 700,function(){

      });
    }

    function hanleDelete(id) {
      if (id == '') {
        return;
      }
      HFConfirm("确认要删除选中该信息吗？", "", "warning", function(isConfirm){
        if(isConfirm){

          const url = '/hfapplication/gdsti/c/relyonWorkplaceProjectUpdateLog/delete.do';
          proxy.$http.post(url, {ids: id}).then(res => {
            if (res.data.state == 0) {
              HFToastrTipResult(res.data);
              loadData();
            }
          }).catch(err => {

          });

        }
      });

    }

    function examine(id) {
      if (id == '') {
        return;
      }
      const url = '/hfapplication/gdsti/c/relyonWorkplaceProjectUpdateLog/examine.do';
      let data = {
        id: id,
        status : 0
      };
      proxy.$http.post(url, data).then(res => {
        if (res.data.state == 0) {
          proxy.ElMessage.success(res.data.message);
          loadData();
        }
      }).catch(err => {

      });
    }

    const tablePagerSelect = ref({
      total: 1,
      page: 1,
      list: [{}]
    })
    const projectId = ref('');
    function getProjectList(){
      let url = '/hfapplication/gdsti/c/relyonWorkplaceProject/list2.do';
      proxy.$http.post(url, {limit: 10, offset: (currentPage.value - 1) * 10})
          .then(res => {
            if (res.data.state == 0) {
              tablePagerSelect.value = res.data.result;
              if (res.data.result.list.length > 0) {
                projectId.value = res.data.result.list[0].id;
              }
              isShow.value = true;
            }
          }).catch(err => {
      })

    }

    const multipleSelectionProject = ref([])
    function handleSelectionChangeProject(selections) {
      multipleSelectionProject.value = selections;
    }
    function handleRowChangeSelect(data) {
      if (data) {
        projectId.value = data.id
      }
    }



    function indexFormatter(row, column, cellValue, index) {
      return row + 1 + '';
    }

    function handleView(index) {
      console.log(index)
    }

    function statusFormatter(row, column, cellValue, index) {
      console.log(cellValue)
      if (cellValue == 1) {
        return '审核通过';
      } else if (cellValue == 0) {
        return '审核中';
      } else if (cellValue == -1) {
        return '审核不通过';
      } else if (cellValue == -2) {
        return '待提交审核';
      }
    }

    function defaultFormatter(row, column, cellValue, index) {
      return '//';
    }

    function projectSelect() {
      //TODO: 添加页面
      // this.infoDialog = true;
      // this.infoDialogTitle = "申请变更";
      // this.iframeUrl = "/hfapplication/gdsti/v/relyonWorkplaceProjectUpdateLog/dialogAdd.do?id="+projectId.value;

      openDialog("updateLogAdd","申请变更","/hfapplication/gdsti/v/relyonWorkplaceProjectUpdateLog/dialogAdd.do?id="+projectId.value,"",900, 700,function(){
        isShow.value = false;
        loadData();
      });
    }

    function closeMyDialog(){
      infoDialog.value = false;
      loadData();
    }

    onMounted(() => {
      loadData();
      window.closeVueDialog = () => {
        closeMyDialog()
      }
    })

    return {
      tablePager, pageChange, search, currentPage, multipleSelection, indexFormatter, handleUpdate, hanleDelete,defaultFormatter,examine,
      handleView, handleSelectionChange, statusFormatter,iframeUrl, infoDialog,infoDialogTitle,isShow,getProjectList,tablePagerSelect,projectSelect,
      projectId,multipleSelectionProject,handleSelectionChangeProject,handleRowChangeSelect,loading
    }
  },
  methods : {
    func1() {

    }
  }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
</style>


<template>
    <div class="user-info" v-loading="loading">
        <div class="info-title">{{title}}</div>
        <div class="desc" v-html="content"></div>
        <div class="bottom-btns">
            <el-button type="default" @click="back">返回</el-button>
            <el-button type="primary" @click="nextClick">已了解须知，下载收录证明</el-button>
        </div>
    </div>
</template>

<script>
import {ElButton} from 'element-plus'
import {ref, onMounted, getCurrentInstance} from 'vue';
import {useRoute} from 'vue-router'

export default {
    props:{
        title: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        }
    },
    setup() {
        const loading = ref(false);
        const route = useRoute();
        const query = route.query;
        const dataIds = ref('');
        onMounted(() => {
            dataIds.value = query.dataIds;
        })

        return {
            dataIds,loading
        }
    },
    components:{ElButton},
    methods:{
        back(){
            this.$router.back();
        },
        nextClick(){
            console.log("dataIds:",this.dataIds);
            this.loading = true;

            this.$http.post('/hfapplication/gdsti/c/remittanceConfigure/exportProve.do', {ids: this.dataIds}).then(res => {
                this.loading = false;
                if (res.data.state == 0) {
                    let path = res.data.result.path;
                    window.open('/hfapplication/gdsti/c/remittanceConfigure/exportWord.do?path=' + path + "&fileName=收录证明.docx", '_blank');
                }
            }).catch(err => {
                this.loading = false;
            });
        }
    }
}
</script>


<style lang="less" scoped>
@import '../../public/static/css/userBaseInfo.less';
.desc{
    max-height: 680px;
    overflow: auto;
    padding: 30px 10px;
    border: 1px solid #d4d4d4;
}

</style>

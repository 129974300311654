<template>
    <div class="user-info" v-loading="loading">


        <div class="">
            <div class="topic-search">

                <div class="demo-input-suffix">

                    <!--          人才库-->
                    <el-form label-width="120px">
                        <el-row>

                            <el-col :span="6">
                                <el-form-item label="依托单位名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchParam.hrwName" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="平台名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchParam.projectName" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>


                            <el-col :span="6">
                                <el-form-item label="职称" style="padding: 5px;">
                                    <el-select style="width: 100%;" v-model="searchParam.title" placeholder="请选择"
                                               clearable>
                                        <el-option label="正高" value="正高"></el-option>
                                        <el-option label="副高" value="副高"></el-option>
                                        <el-option label="中级" value="中级"></el-option>
                                        <el-option label="初级" value="初级"></el-option>
                                        <el-option label="其他" value="其他"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="学历" style="padding: 5px;">
                                    <el-select style="width: 100%;" v-model="searchParam.education" placeholder="请选择"
                                               clearable>
                                        <el-option label="博士" value="博士"></el-option>
                                        <el-option label="硕士" value="硕士"></el-option>
                                        <el-option label="本科" value="本科"></el-option>
                                        <el-option label="专科" value="专科"></el-option>
                                        <el-option label="其他" value="其他"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>


                        </el-row>
                        <el-row>

                            <el-col :span="6">
                                <el-form-item label="实验室职务" style="padding: 5px;">
                                    <el-select style="width: 100%;" v-model="searchParam.laboratoryPosition"
                                               placeholder="请选择" clearable>
                                        <el-option label="实验室主任" value="实验室主任"></el-option>
                                        <el-option label="实验室副主任" value="实验室副主任"></el-option>
                                        <el-option label="实验室常务副主任" value="实验室常务副主任"></el-option>
                                        <el-option label="学术带头人" value="学术带头人"></el-option>
                                        <el-option label="实验室秘书" value="实验室秘书"></el-option>
                                        <el-option label="固定研究人员" value="固定研究人员"></el-option>
                                        <el-option label="学术委员会主任" value="学术委员会主任"></el-option>
                                        <el-option label="学术委员会委员" value="学术委员会委员"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="实验室入职时间" style="padding: 5px;">
                                    <el-date-picker
                                        v-model="searchParam.entryTime"
                                        value-format="YYYY-MM-DD"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="实验室离职时间" style="padding: 5px;">
                                    <el-date-picker
                                        v-model="searchParam.resignationTime"
                                        value-format="YYYY-MM-DD"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="年龄"
                                              style="padding: 5px;display: flex;justify-content: space-between;">
                                    <el-input type="number" v-model="searchParam.ageStart"
                                              style="width: 40%;appearance: none;" placeholder="最小年龄"/>
                                    <span style="width: 20%;text-align: center;">至</span>
                                    <el-input type="number" v-model="searchParam.ageEnd"
                                              style="width: 40%;appearance: none;" placeholder="最大年龄"/>
                                </el-form-item>
                            </el-col>


                        </el-row>
                    </el-form>


                </div>


            </div>

        </div>
        <div style="padding-bottom: 10px;display: flex;justify-content: right;">
            <button type="button" class="btn btn-primary" @click="cleanParam" style="margin-right: 10px;">清空</button>
            <button type="button" class="btn btn-primary" @click="search">查询</button>

        </div>
        <div class="collect-head">
            <div class="select">
                <el-badge :value="multipleSelection.length" class="item">
                    <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除</el-button>
                </el-badge>
                <el-button type="primary" class="table-btns" @click="showEdit('')">新增</el-button>
                <el-button type="primary" class="table-btns" @click="excelDownload()">下载excel导入模板
                </el-button>
                <el-button type="primary" class="table-btns" @click="importData()">excel导入
                </el-button>
                <el-button type="primary" class="table-btns" @click="exportLibraryInformation()">导出库信息</el-button>
            </div>
        </div>


        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"/>
            <el-table-column prop="unitName" label="依托单位"/>
            <el-table-column prop="platformName" label="平台/实验室"/>
            <el-table-column prop="name" label="人才名称"/>
            <el-table-column prop="sexName" label="性别"/>
            <el-table-column prop="age" label="年龄"/>
            <el-table-column prop="professionalTitleName" label="职称"/>
            <el-table-column prop="educationName" label="学历"/>
            <el-table-column prop="positionName" label="实验室职务"/>
            <el-table-column prop="entryTime" label="入职时间" :formatter="formatDate"/>
            <el-table-column prop="resignationTime" label="离职时间" :formatter="formatDate"/>

            <el-table-column label="操作" width="250">
                <template #default="scope">


                    <el-button style="margin-top: 10px;" type="success" size="small" @click="showInfo(scope.row.id)">
                        详情
                    </el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showEdit(scope.row.id)">
                        修改
                    </el-button>
                    <el-button style="margin-top: 10px;" type="danger" size="small" @click="handleDelete(scope.row.id)">
                        删除
                    </el-button>

                </template>
            </el-table-column>
        </el-table>

        <Pager  :totalPage="tablePager.totalPage"
                :total="tablePager.total"
               :currentPage="currentPage"
                @pageChange="pageChange"
                @sizeChange="sizeChange" class="pager"/>

        <div class="bottom-btns">
            <el-button type="default" @click="back">返回</el-button>
        </div>

        <el-dialog v-model="isUploadShow" title="导入完成前请勿刷新页面或关闭浏览器"
                   :show-close="isSuccessShow" style="text-align: center;" width="20%"
                   :close-on-press-escape="false" :close-on-click-modal="false">

            <el-progress type="circle" :percentage="percentageNumber" v-if="!isSuccessShow"></el-progress>
            <el-row v-if="isSuccessShow">
                <el-col :sm="6" :lg="24">
                    <el-result :icon="uploadIcon" title="" :subTitle="isSuccessStr" style="padding-bottom: 10px;">
                    </el-result>
                    <div v-if="errorDataList.length > 0" style="text-align: center;padding-bottom: 10px;">
                        <el-button type="primary" @click="exportToExcel()">下载存疑数据</el-button>
                    </div>

                </el-col>
            </el-row>


        </el-dialog>

    </div>
</template>

<script>

import dayjs from 'dayjs';
import {ElButton, ElTable, ElTableColumn} from 'element-plus';
import Pager from '../../../components/common/Pager'
import {MoreFilled} from '@element-plus/icons-vue'
import {getCurrentInstance, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router'
import * as XLSX from 'xlsx';

export default {
    components: {ElTable, ElTableColumn, ElButton, Pager, MoreFilled},
    setup() {

        const XLSX = require('xlsx');
        const {proxy} = getCurrentInstance();
        const route = useRoute();

        const currentPage = ref(1);
        const limit = ref(10);

        const loading = ref(false);
        const searchKey = ref('');
        const uploadIcon = ref('success');


        // 检索字段
        const searchParam = ref({
            hrwName: '',
            projectName: '',
            title: '',
            education: '',
            entryTime: '',
            resignationTime: '',
            ageStart: '',
            ageEnd: '',
            laboratoryPosition: '',
            number: '',
            projectType: '',
            moneyStart: '',
            moneyEnd: '',
            projectDate: '',
            awardTime: '',
            awardType: '',
        });


        const tablePager = ref({
            total: 1,
            totalPage: 1,
            list: []
        })

        function loadData() {
            loading.value = true;
            let paramData = getParams();
            proxy.$http.post('/gdsti2/lab/c/labLibPerson/list', paramData)
                .then(res => {
                    loading.value = false
                    if (res.data.state == 0) {
                        tablePager.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }

        function cleanParam() {
            Object.keys(searchParam.value).forEach((key)=>{
                searchParam.value[key] = "";
            })
            loadData();
        }

        function pageChange(index) {
            console.log('pageChange')
            currentPage.value = index;
            loadData();
        }

        function sizeChange(number) {
            limit.value = number;
            loadData();
        }

        //点击查询按钮
        function search() {
            //翻到第一页
            currentPage.value = 1;
            loadData();
        }


        const multipleSelection = ref([])

        function handleSelectionChange(selections) {
            multipleSelection.value = selections;
        }


        function indexFormatter(row, column, cellValue, index) {
            return row + 1 + '';
        }

        function formatDate(row, column, cellValue, index) {
            if (cellValue) {
                return dayjs(cellValue).format('YYYY-MM-DD');
            } else {
                return '';
            }
        }


        function handleDeleteAll() {
            if (multipleSelection.value.length < 1) {
                proxy.ElMessage.error('至少选中一项!');
                return;
            } else {
                handleDelete(multipleSelection.value.map(obj => obj.id).join(','));
            }

        }

        function handleDelete(id) {
            if (id == '') {
                return;
            }

            HFConfirm("确认要删除选中信息吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {
                    loading.value = true;
                    proxy.$http.post('/gdsti2/lab/c/labLibPerson/delete.do', {ids: id}).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            loadData();
                        }
                        loading.value = false;
                    }).catch(err => {
                        loading.value = false;
                    });
                }
            });
        }

        function showInfo(id) {

            openDialog("showInfo", "详情", "/gdsti2v/lab/labLibPerson/form?disabled=true&id=" + id, "", '95%', '95%', function () {
                loadData();
            });

        }

        function showEdit(id) {
            let title = "新增"
            if (id) {
                title = '编辑'
            }
            openDialog("showEdit", title, "/gdsti2v/lab/labLibPerson/form?id=" + id, "", '95%', '95%', function () {
                loadData();
            });

        }

        //导入相关参数
        const isUploadShow = ref(false);        //是否显示导入进度
        const isSuccessShow = ref(false);      //是否显示导入完成
        const percentageNumber = ref(0);      //导入进度百分比
        const isSuccessStr = ref('');       //导入完成显示文本
        const errorDataList = ref([]);      //存疑数据
        const uploadProgressKey = ref('');  //导入进度key
        //导入相关参数end

        //excel导入
        function importData() {

            const that = this;
            openDialog('selectPlatform', '选择归属平台（项目）', '/gdsti2v/lab/labPlatform/selectPlatform?isMultiple=false', '', 1200, 650, function (selectPlatformRes) {
                if (selectPlatformRes && selectPlatformRes.selectItemArr && selectPlatformRes.selectItemArr.length > 0) {
                    openDialog('fileUploaderDialog', '上传导入文件', '/gdsti2v/common/fileUploaderDialog?relateType=gdsti_lab_lib_person', {}, '50%', '50%', async function (fileUploaderDialogRes) {
                        if (fileUploaderDialogRes && fileUploaderDialogRes.fileList) {

                            //生成导入进度条key
                            const keyRes = await proxy.$http.post('/gdsti2/web/c/webManager/generateUploadProgressKey.do', {suffixStr: "exportRCK"});
                            uploadProgressKey.value = keyRes.data.result;

                            const param = {
                                speedOfProgressKey: keyRes.data.result,
                                dataFileId: fileUploaderDialogRes.fileList[0].id,
                                platformId: selectPlatformRes.selectItemArr[0].id
                            }
                            //开始导入前，重置进度条
                            resrtProgress();

                            //获取进度条定时器
                            let timer = setInterval(() => {
                                //需要定时执行的代码
                                // this.isSuccessShow = false;
                                proxy.$http.post('/gdsti2/web/c/webManager/getUploadProgress.do', {key: uploadProgressKey.value}).then(getUploadProgressRes => {
                                    if (getUploadProgressRes.data.state == 0) {
                                        percentageNumber.value = getUploadProgressRes.data.result;
                                    }
                                }).catch(err => {
                                    //如果异常，清空定时器
                                    clearInterval(timer);
                                })
                                //导入完成，清空定时器
                                if (percentageNumber.value == 100) {
                                    clearInterval(timer);
                                    //导入完成
                                }
                            }, 500);

                            //调用导入方法开始导入
                            //显示进度条
                            isUploadShow.value = true;
                            proxy.$http.post('/gdsti2/lab/c/labLibPerson/importData.do', param).then(res => {
                                if (res.data.state == 0) {
                                    isSuccessShow.value = true;
                                    isSuccessStr.value = JSON.parse(res.data.result).message;
                                    errorDataList.value = JSON.parse(res.data.result).errorDataList;
                                }
                                clearInterval(timer);
                            }).catch(err => {
                                clearInterval(timer);
                            })

                        }

                    });
                }


            });
        }

        //重置进度条，提示文本等等
        function resrtProgress(){
            isSuccessShow.value = false;
            percentageNumber.value = 0;
            isSuccessStr.value = '';
        }


        //导出库信息
        function exportLibraryInformation() {

            let paramData = getParams();
            const params = new URLSearchParams(Object.entries(paramData));

            console.log("导出", paramData);

            window.open(`/gdsti2/lab/c/labLibPerson/exportData.do?${params}`);

        }

        function getParams() {

            return {
                hrwName: searchParam.value.hrwName,
                projectName: searchParam.value.projectName,
                title: searchParam.value.title,
                education: searchParam.value.education,
                entryTimeStart: searchParam.value.entryTime && searchParam.value.entryTime.length > 0 ? searchParam.value.entryTime[0] : '',
                entryTimeEnd:  searchParam.value.entryTime && searchParam.value.entryTime.length > 1 ? searchParam.value.entryTime[1] : '',
                resignationTimeStart: searchParam.value.resignationTime && searchParam.value.resignationTime.length > 0 ? searchParam.value.resignationTime[0] : '',
                resignationTimeEnd: searchParam.value.resignationTime && searchParam.value.resignationTime.length > 1 ? searchParam.value.resignationTime[1] : '',
                ageStart: searchParam.value.ageStart,
                ageEnd: searchParam.value.ageEnd,
                laboratoryPosition: searchParam.value.laboratoryPosition,
                limit: limit.value,
                offset: (currentPage.value - 1) * limit.value,

            };
        }




        onMounted(() => {
            loadData();
        })

        return {
            errorDataList,
            tablePager,
            pageChange,
            sizeChange,
            search,
            currentPage,
            multipleSelection,
            indexFormatter,
            isUploadShow,
            percentageNumber,
            isSuccessShow,
            isSuccessStr,

            handleSelectionChange,
            formatDate,
            handleDeleteAll,
            handleDelete,
            showInfo,
            showEdit,
            loading,
            exportLibraryInformation,
            searchKey,
            importData,
            uploadIcon,
            searchParam,
            cleanParam,
            route,
        }
    },
    methods: {
        back() {
            this.$router.back();
        },
        excelImport() {

        },
        excelDownload() {
            window.open(`/gdsti2/lab/c/labLibPerson/downTemp.do`);
        },
        //导出存疑数据
        exportToExcel() {

            // 创建一个工作簿
            const wb = XLSX.utils.book_new();
            // 创建一个工作表
            const ws = XLSX.utils.json_to_sheet(this.errorDataList);
            // 将工作表添加到工作簿
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            // 保存工作簿为Excel文件
            XLSX.writeFile(wb, '存疑数据.xlsx');
            this.$message({
                message: '下载成功，请通过浏览器下载记录查看',
                type: 'success'
            });

        },


    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

.topic-search {

    line-height: 25px;
    font-size: 14px;


    .search-input {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        padding: 5px 9px;
        margin-left: 9px;
        margin-right: 16px;
        font-size: 14px;
        line-height: 14px;
    }

    .btn {
        display: inline-block;
        padding-top: 2px;
        padding-bottom: 2px;
    }
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/deep/ input[type="number"] {
    -moz-appearance: textfield;
}

</style>

